<template>
  <v-card flat tile>
    <v-img aspect-ratio="1"
      :src="`https://maps.googleapis.com/maps/api/staticmap?${mapParams(location)}&key=${apiKey}`">
    </v-img>
  </v-card>
</template>

<script>
export default {
  props: {
    location: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      apiKey: 'AIzaSyDNnD3uOFdgO1z9MiZ5ZyD5DciQ0xYp8ag'
    }
  },
  methods: {
    mapParams(location) {
      if (location) {
        let p = 'center=' + location.latlng.lat + ',' + location.latlng.lng
        p += '&markers=' + location.latlng.lat + ',' + location.latlng.lng
        p += '&zoom=14&size=320x320&scale=2'
        return p
      }
    }
  }
}
</script>
