<template>
  <div>
    <div v-if="!!profile">
      <v-row>
        <v-col cols="8">
          <v-badge id="prime-badge" :value="hasPrime" icon="mdi-check-decagram" color="secondary--text">
            <p class="text-h5">{{ profile.name }}</p>
          </v-badge>
          <p class="text-subtitle-2 font-italic">
            <span v-for="s in profile.skills" :key="s">
              {{ $store.getters.skillName(s) }},
            </span>
          </p>
          <p class="text-subtitle-1">{{ profile.introduction }}</p>
        </v-col>
        <v-col cols="4">
          <v-avatar size="120">
            <img :src="$store.getters.photoUrl(id, avatarPhoto)" alt="Photo" />
          </v-avatar>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12">
          <v-tabs v-model="tab" grow slider-color="secondary">
            <v-tab href="#biog">Biog</v-tab>
            <v-tab v-if="profile.photos" href="#photos">Photos</v-tab>
            <v-tab v-if="offer" href="#offers">Offers</v-tab>
          </v-tabs>

          <v-tabs-items v-model="tab">
            <!-- Resume -->
            <v-tab-item value="biog">
              <v-card flat tile color="white" class="mt-4 pa-4">
                <div v-html="profile.resume" id="resume"></div>
              </v-card>
            </v-tab-item>

            <!-- Photos -->
            <v-tab-item v-if="profile.photos" value="photos">
              <v-card flat tile class="pa-4">
                <v-carousel v-model="featureIndex" height="auto">
                  <v-carousel-item v-for="(feature, n) in featurePhotos" :key="n"
                    :src="$store.getters.photoUrl(id, feature)">
                  </v-carousel-item>
                </v-carousel>
              </v-card>
            </v-tab-item>

            <!-- Offers -->
            <v-tab-item v-if="offer" value="offers">
              <v-card flat tile class="pa-4">
                <div v-if="offer.sent" class="mt-1">
                  <span class="font-weight-bold">Sent:</span>
                  <span v-for="s in offer.sent" :key="s">
                    <br>{{ formatTime(s) }}
                  </span>
                </div>
                <div class="mt-1">
                  <span class="font-weight-bold">Seen:</span>
                  <template v-if="offer.seen">
                    <br>{{ formatTime(offer.seen) }}
                  </template>
                  <template v-else>
                    <br><span class="font-italic">Offer not yet seen</span>
                  </template>
                </div>
                <div class="mt-1">
                  <span class="font-weight-bold">Fee: </span>
                  £{{offer.fee}}
                </div>
                <div class="mt-1">
                  <span class="font-weight-bold">Skills: </span>
                  {{offer.skills}}
                </div>
                <div class="mt-1">
                  <span class="font-weight-bold">Note: </span>
                  {{offer.note}}
                </div>
                <div class="mt-1">
                  <span class="font-weight-bold">Calls: </span>
                  {{ offer.multicall ? 'Multiple calls out' : 'Only call out' }}
                </div>
              </v-card>
            </v-tab-item>
          </v-tabs-items>

        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import { eventBus } from '@/main'
import { fbfs, fbfuncs } from '@/plugins/firebase'
import { httpsCallable } from 'firebase/functions'
import { doc, getDoc, updateDoc, increment } from 'firebase/firestore'

export default {
  props: {
    id: {
      type: String,
      required: true
    },
    offerId: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      profile: null,
      avatarPhoto: null,
      featurePhotos: [],
      tier: { subscribed: false },
      offer: null,
      tab: null,
      featureIndex: 0,
    }
  },
  methods: {
    refreshPhotos(photos) {
      this.avatarPhoto = null
      this.featurePhotos = []
      if (photos) {
        Object.keys(photos).sort().forEach((key) => {
          if (key === 'avatar') {
            this.avatarPhoto = photos.avatar
          }
          if (key.startsWith('feature') && photos[key]) {
            this.featurePhotos.push(photos[key])
          }
        })
      }
    },
    formatTime(time) {
      return Intl.DateTimeFormat('en-gb', {
        weekday:'long', day: 'numeric', month: 'long', year: 'numeric',
        hour: 'numeric', minute: 'numeric'
      }).format(new Date(time))
    }
  },
  computed: {
    hasPrime() {
      return !!this.tier.subscribed
    }
  },
  async created() {
    if (this.id) {
      eventBus.$emit('appAlterAppBar', { loading: true })
      try {
        // Load profile data
        const profileSnap = await getDoc(doc(fbfs, 'profiles', this.id))
        if (!profileSnap.exists()) {
          throw 'Can\'t find profile'
        }
        // Update profile data
        this.profile = profileSnap.data()
        if (!this.profile.skills) {
          this.profile.skills = []
        }
        this.refreshPhotos(this.profile.photos)
        // Load offer
        if (this.offerId) {
          const offerSnap = await getDoc(doc(fbfs, 'offers', this.offerId))
          if (offerSnap.exists()) {
            this.offer = offerSnap.data()
          }
        }
        // Load tier
        const f = httpsCallable(fbfuncs, 'getTier')
        f({ id: this.id })
          .then((result) => {
            this.tier = result.data
          })
          .catch((e) => {
            eventBus.$emit('appWarningSet', { message: 'Error: ' + e })
          })
        // Update view counter
        await updateDoc(doc(fbfs, 'counts', this.id), {
          'viewed.total': increment(1)
        });
        eventBus.$emit('appAlterAppBar', { loading: false })
      } catch (e) {
        eventBus.$emit('appAlterAppBar', { loading: false })
        eventBus.$emit('appWarningSet', { message: 'Error: ' + e })
      }
    }
  },
  beforeDestroy() {
  }
}
</script>

<style scoped>
#resume ::v-deep(h1) {
  font-size: 1.5rem;
  font-weight: 400;
  line-height: 2rem;
  letter-spacing: normal;
  font-family: "Roboto", sans-serif;
  margin-top: 24px;
  margin-bottom: 8px;
}
#resume ::v-deep(h2) {
  font-size: 1.25rem;
  font-weight: 500;
  line-height: 2rem;
  letter-spacing: 0.0125em;
  font-family: "Roboto", sans-serif;
  margin-top: 20px;
  margin-bottom: 8px;
}
#resume ::v-deep(p) {
  font-size: 1rem;
  font-weight: normal;
  line-height: 1.5rem;
  letter-spacing: 0.009375em;
  font-family: "Roboto", sans-serif;
  margin-top: 0px;
  margin-bottom: 8px;
}
#prime-badge :deep(i) {
  font-size: 20px;
}
</style>