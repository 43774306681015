<template>
  <div>
    <tab-title>Settings</tab-title>
    <tab-content>
      <v-switch v-model="enableHelpCards" flat color="secondary" class="v-input--reverse v-input--expand">
        <template v-slot:label>
          <v-icon class="mr-2">mdi-book-open-variant</v-icon>
          Enable Help Cards
        </template>
      </v-switch>
    </tab-content>
  </div>
</template>

<script>
import TabTitle from '@/components/TabTitle.vue'
import TabContent from '@/components/TabContent.vue'

export default {
  name: 'AppConfigTab',
  components: { TabTitle, TabContent },
  computed: {
    enableHelpCards: {
      get() {
        return this.$store.state.enableHelpCards
      },
      set(value) {
        this.$store.commit('setEnableHelpCards', value)
      }
    }
  },
}
</script>

<style>
.theme--light.v-input--switch .v-input--switch__thumb {
    color: #7a9e9f;
}

/* Reversed switches
    https://github.com/vuetifyjs/vuetify/issues/7283
    https://codepen.io/alanaktion/pen/OJPQKdx */

.v-input--reverse .v-input__slot {
  flex-direction: row-reverse;
  justify-content: flex-end;
  .v-application--is-ltr & {
    .v-input--selection-controls__input {
      margin-right: 0;
      margin-left: 8px;
    }
  }
  .v-application--is-rtl & {
    .v-input--selection-controls__input {
      margin-left: 0;
      margin-right: 8px;
    }
  }
}

.v-input--expand .v-input__slot {
  /* justify-content: space-between; */
  .v-label {
    display: block;
    flex: 1;
  }
}
</style>