const getters = {
  offer(state) {
    return state.offer
  },
  offerBarred(state) {
    if (state.offer.from) {
      return false
    } else {
      return true
    }
  }
}

const mutations = {
  setOffer(state, payload) {
    state.offer = payload
  }
}

const state = {
  offer: null
}

export default {
  state,
  getters,
  mutations
}
