<template>
  <v-card @click="buttonClick(2)"
    :loading="loadingColor"
    tile color="white black--text"
  >
    <div class="d-flex flex-no-wrap justify-space-between">
      <div>
        <v-card-title class="text-subtitle-1 font-weight-medium pr-2">
          <v-badge id="prime-badge" :value="hasPrime" icon="mdi-check-decagram" color="secondary--text">{{ profile.name }}</v-badge>
        </v-card-title>
        <v-card-subtitle>
          <div class="font-italic pr-2">
            <template v-for="s in profile.skills">
              {{ $store.getters.skillName(s) }},
            </template>
          </div>
          <p class="mt-2 mb-0">
            {{ profile.introduction }}
          </p>
          <p v-if="mode==='search'" class="text-tag mt-2 mb-0">
            <template v-if="hasBiog">
              <v-icon small>mdi-check</v-icon> Biog
            </template>
            <template v-if="hasPhoto">
              <v-icon small>mdi-check</v-icon> Photos
            </template>
          </p>
        </v-card-subtitle>
        <v-card-text v-if="mode==='offer'" class="pr-2">
          <template v-if="profile.offer.sent">
            <v-icon small>mdi-email-outline</v-icon>
            {{ formatTime(profile.offer.sent) }}
          </template>
          <template v-if="profile.offer.seen">
            <br>
            <v-icon small>mdi-email-open-outline</v-icon>
            {{ formatTime(profile.offer.seen) }}
          </template>
        </v-card-text>
      </div>
      <v-avatar size="80" class="mt-4 mx-4">
        <img :src="$store.getters.photoUrl(profile.id, profile.avatar)" alt="Photo" />
      </v-avatar>
    </div>
    <div class="button-row">
      <v-btn small fab elevation="0" v-blur class="ml-3"
        v-if="buttons[1].icon"
        @click.stop="buttonClick(1)"
        :disabled="buttons[1].disabled"
        :loading="buttons[1].loading"
        color="secondary--text"
      >
        <v-icon>{{ buttons[1].icon }}</v-icon>
      </v-btn>
      <v-btn small fab elevation="0" v-blur class="ml-3"
        v-if="buttons[0].icon"
        @click.stop="buttonClick(0)"
        :disabled="buttons[0].disabled"
        :loading="buttons[0].loading"
        color="secondary--text"
      >
        <v-icon>{{ buttons[0].icon }}</v-icon>
      </v-btn>
    </div>
  </v-card>
</template>

<script>
import { eventBus } from '@/main'
import { fbfuncs } from '@/plugins/firebase'
import { httpsCallable } from 'firebase/functions'

const SELECT = 0
const MESSAGE = 1
const VIEW = 2

export default {
  props: {
    mode: {
      type: String,
      required: true
    },
    profile: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      tier: { subscribed: false },
      loading: false,
      buttons: [
        { icon: null, disabled: false, loading: false },
        { icon: null, disabled: false, loading: false }
      ]
    }
  },
  computed: {
    hasPrime() {
      return !!this.tier.subscribed
    },
    hasBiog() {
      return this.profile.resumeLength > 100
    },
    hasPhoto() {
      return this.profile.photoCount > 1
    },
    loadingColor() {
      return this.loading ? 'secondary' : false
    }
  },
  methods: {
    formatTime(time) {
      return Intl.DateTimeFormat('en-gb', {
        weekday:'long', day: 'numeric', month: 'long', year: 'numeric',
        hour: 'numeric', minute: 'numeric'
      }).format(new Date(time))
    },
    buttonClick(button) {
      const profile = Object.assign({}, this.profile, { tier: this.tier })
      if (button === MESSAGE) {
        eventBus.$emit('cardButtonClick', profile, 'messageProfile')
      } else if (button === SELECT) {
        if (this.profile.selected) {
          eventBus.$emit('cardButtonClick', profile, 'unselectProfile')
        } else {
          eventBus.$emit('cardButtonClick', profile, 'selectProfile')
        }
      } else if (button === VIEW) {
        eventBus.$emit('cardButtonClick', profile, 'viewProfile')
      }
    },
  },
  created() {
    if (this.mode === 'selected' || this.mode === 'search') {
      if (this.profile.selected) {
        this.buttons[SELECT].icon = 'mdi-bookmark'
      } else {
        this.buttons[SELECT].icon = 'mdi-bookmark-outline'
      }
    }
    if (this.mode === 'selected' || this.mode === 'offer') {
      if (this.profile.offer) {
        if (this.profile.offer.seen) {
          this.buttons[MESSAGE].icon = 'mdi-email-open'
        } else if (this.profile.offer.sent) {
          this.buttons[MESSAGE].icon = 'mdi-email'
        } else {
          this.buttons[MESSAGE].icon = 'mdi-email-outline'
        }
      } else {
        this.buttons[MESSAGE].icon = 'mdi-email-outline'
      }
    }
    if (this.profile.tier) {
      this.tier = this.profile.tier
    } else {
      // Load tier
      this.loading = true
      const f = httpsCallable(fbfuncs, 'getTier')
      f({ id: this.profile.id })
        .then((result) => {
          this.tier = result.data
          delete this.tier.id
          this.loading = false
        })
        .catch((e) => {
          this.loading = false
          eventBus.$emit('appWarningSet', { message: 'Error: ' + e })
        })
    }
  },
}
</script>

<style scoped>
  .button-row {
    bottom: 0;
    right: 0;
    position: absolute;
    margin: 0 12px 12px 0;
  }
  .text-tag {
    font-size: 0.7rem;
    font-weight: 400;
    letter-spacing: 0.05em;
    text-transform: uppercase;
  }
  #prime-badge :deep(i) {
    font-size: 16px;
  }
</style>