<template>
  <div>
    <div id="places-map"></div>
    <v-textarea
      v-model="searchText"
      label="Address"
      placeholder="Name or postcode of venue"
      @focus="$emit('focus')"
      @keyup.enter="doSearch"
      @click:append-outer="doSearch"
      @click:clear="clearSearch"
      prepend-icon="mdi-map-marker"
      append-outer-icon="mdi-magnify"
      :rules="inputRules"
      :disabled="disabled"
      :readonly="readonly"
      :clearable="!readonly"
      no-resize auto-grow rows="1"
    ></v-textarea>
    <!-- TODO: protect text area agains manual deleting -->
    <v-list v-for="(s, i) in searchResults" v-bind:key="i" class="py-0">
      <v-list-item v-on:click="selectItem(i)">
        <v-list-item-content v-text="s.address" class="text-left"></v-list-item-content>
      </v-list-item>
    </v-list>
  </div>
</template>

<script>
import { eventBus } from '@/main'

export default {
  props: {
    defaultSearch: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    },
    readonly: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      service: null,
      searchText: this.defaultSearch,
      searchResults: [],
      searchValid: true,
      inputRules: [ () => this.searchValid || 'Search for and select a location' ]
    }
  },
  watch: {
    defaultSearch() {
      this.searchText = this.defaultSearch
      this.searchResults = []
      if (!this.searchText) {
        this.searchValid = false
      }
    }
  },
  methods: {
    doSearch() {

      this.searchResults = []

      if (!this.searchText) {
        return
      }

      this.searchText = this.searchText.trim()

      if (!this.searchText) {
        return
      }

      let searchRequest = {
        query: this.searchText,
        fields: ['name', 'formatted_address', 'geometry']
      }

      this.service.findPlaceFromQuery(searchRequest, (results, status) => {

        if (status === 'OK') {

          for (const result of results) {

            let address = result.formatted_address
            if (!result.formatted_address.startsWith(result.name)) {
              address = result.name + ', ' + address
            }

            this.searchResults.push({
              name: result.name,
              address: address,
              latlng: {
                lat: result.geometry.location.lat(),
                lng: result.geometry.location.lng()
              }
            })
          }
        } else {
          this.searchValid = false
          this.$emit('selected', null)

          if (status === 'ZERO_RESULTS') {
            eventBus.$emit('appWarningSet', { color: 'warning', message: 'Couldn\'t find that place' })
          } else {
            eventBus.$emit('appWarningSet', { message: 'Error: ' + status })
          }

        }
      })
    },
    clearSearch() {
      this.searchValid = false
      this.searchResults = []
      this.$emit('selected', null)
    },
    selectItem(index) {
      this.$emit('selected', this.searchResults[index])
      this.searchText = this.searchResults[index].address
      this.searchValid = true
      this.searchResults = []
    },
    resetForms() {
      this.searchResults = []
    }
  },
  created() {
    eventBus.$on('panelReset', this.resetForms)
  },
  mounted() {
    this.service = new window.google.maps.places.PlacesService(document.getElementById('places-map'));
  },
  beforeDestroy() {
    eventBus.$off('panelReset', this.resetForms)
  }
}
</script>

<style>

</style>