<template>
  <div>
    <tab-title>The Gig {{ tabTitleNote }}</tab-title>
    <tab-content>
      <v-row>
        <v-col cols="12" sm="8" offset-sm="2">
          <action-card class="mb-4">Create a title and set a date for your gig. Type the name or postcode of the venue, press the search button and choose the correct location from the list. Save your gig using the tick button in the top right corner. You can add further gig details too.</action-card>
          <!-- Title field -->
          <v-text-field
            v-model.trim="formData.title"
            @focus="warnReadonly"
            :rules="[ inputRules.titleMinCheck, inputRules.titleMaxCheck]"
            :disabled="formDataDisabled"
            :readonly="gigFrozen"
            label="Title" placeholder="The title of this gig"
            prepend-icon="mdi-card-text-outline"
          >
          </v-text-field>

          <!-- Date picker -->
          <v-menu v-model="showDatePicker" :close-on-content-click="false" transition="scale-transition" offset-y
            min-width="auto">
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                :value="formattedDate"
                v-bind="attrs" v-on="on"
                @focus="warnReadonly"
                :rules="[ inputRules.dateCheck ]"
                label="Date" prepend-icon="mdi-calendar-outline"
                readonly
                :clearable="!gigFrozen"
                @click:clear="formData.date=''"
                :disabled="formDataDisabled"
              ></v-text-field>
            </template>
            <v-date-picker v-model="formData.date"
              @input="showDatePicker=false"
              :readonly="gigFrozen"
              first-day-of-week="1"
              no-title
              color="primary"
            ></v-date-picker>
          </v-menu>
        </v-col>

        <v-col cols="12" sm="8" offset-sm="2">
          <!-- Location editor -->
          <location-editor
            :initial-location="formData.location"
            @focus="warnReadonly"
            @selected="setLocation"
            :disabled="formDataDisabled"
            :readonly="gigFrozen"
          ></location-editor>
        </v-col>

        <v-col cols="12" sm="8" offset-sm="2" class="mt-4">
          <!-- Details -->
          <p>
            <v-icon class="mr-3">mdi-file-document-outline</v-icon>
            <span class="theme--light v-label">Details</span>
          </p>
          <tiptap-vuetify v-if="!formDataDisabled"
            class="text-formatted"
            v-model="formData.details"
            :extensions="editorExtensions"
            :toolbar-attributes="{color: 'primarylight onprimarylight--text'}"
            :card-props="{flat:true, outlined: true, color: 'white'}"
            min-height="200" max-height="400"
            placeholder="Further details about the gig for the performers. You can include times and dress code here. Please don't add any personal data (name, phone number, email)."
          />
          <v-card v-else flat tile color="white" class="pa-2">
            <div v-html="formData.details" class="text-formatted grey--text"></div>
          </v-card>
        </v-col>

        <v-col cols="12" sm="8" offset-sm="2">
          <!-- Notes -->
          <action-card class="mt-4">Add any notes for yourself below. These won't be shared with the performers.</action-card>
          <v-text-field
            v-model.trim="formData.notes"
            :disabled="formDataDisabled"
            label="Notes"
            placeholder="Notes for yourself"
            hint=""
            prepend-icon="mdi-file-lock-outline"
          ></v-text-field>
        </v-col>
      </v-row>
    </tab-content>

    <!-- Leave dialog -->
    <v-dialog v-model="showLeaveDialog" persistent max-width="400px">
      <v-card>
        <v-card-title class="primarydark--text">
          Do you want to leave this page?
        </v-card-title>
        <v-card-text>
          You have unsaved changes. If you leave this page you will loose your changes.
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text color="primarydark" @click="showLeaveDialog=false; leaveRoute=null">
            Cancel
          </v-btn>
          <v-btn text color="primarydark" @click="showLeaveDialog=false; $router.push(leaveRoute);">
            Leave
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Archive dialog -->
    <v-dialog v-model="showArchiveDialog" max-width="400px">
      <v-card>
        <v-card-title class="primarydark--text">
          Archive gig?
        </v-card-title>
        <v-card-text>
          Gig data will remain accessible but you won't be able to edit or make searches for this gig.
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text color="primarydark" @click="showArchiveDialog=false">
            Cancel
          </v-btn>
          <v-btn text color="primarydark" @click="archiveGig">
            Archive
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import TabTitle from '@/components/TabTitle.vue'
import TabContent from '@/components/TabContent.vue'
import ActionCard from '@/components/ActionCard.vue'
import LocationEditor from '@/components/LocationEditor.vue'
import { eventBus } from '@/main'
import { mapGetters } from 'vuex'
import { fbfs } from '@/plugins/firebase'
import { collection, doc, addDoc, updateDoc } from 'firebase/firestore'
import { TiptapVuetify, Heading, Bold, Italic, Underline, BulletList, ListItem } from 'tiptap-vuetify'

const SAVE = 0
const ARCHIVE = 1

export default {
  name: 'GigEditorDetailsTab',
  components: { TabTitle, TabContent, ActionCard, LocationEditor, TiptapVuetify },
  data() {
    return {
      formData: {
        title: '',
        date: '',
        location: { name: '', address: '', latlng: { lat: null, lng: null }},
        details: '',
        notes: ''
      },
      showDatePicker: false,
      showArchiveDialog: false,
      showLeaveDialog: false,
      leaveRoute: null,
      inputRules: {
        titleMinCheck: () => this.titleMinValid || 'Minimum 8 letters',
        titleMaxCheck: () => this.titleMaxValid || 'Maximum 60 letters',
        dateCheck: () => (!!this.formData.date && this.formData.date.length === 10) || 'Select a valid date'
      },
      editorExtensions: [
        [Heading, {options: {levels: [1, 2]}}],
        Bold, Italic, Underline, BulletList, ListItem,
      ],
    }
  },
  computed: {
    tabTitleNote() {
      return this.gigArchived ? '(ARCHIVED)' : ''
    },
    titleMinValid() {
      return !!this.formData.title && this.formData.title.length >= 8
    },
    titleMaxValid() {
      return !!this.formData.title && this.formData.title.length <= 60
    },
    formDataValid() {
      return !!this.formData &&
        !!this.formData.location &&
        this.formData.location.latlng.lat !== null &&
        this.formData.location.latlng.lng !== null &&
        this.titleMinValid && this.titleMaxValid &&
        this.formData.date && this.formData.date.length === 10
    },
    formDataSaveable() {
      return !!this.formData && this.formDataValid && this.formDataChanged
    },
    formDataDisabled() {
      return !this.$store.state.user.emailVerified || !this.profileValid || this.gigArchived
    },
    gigFrozen() {
      return this.gigProfilesWithOffer.length > 0
    },
    formDataChanged() {
      if (!this.formData) {
        return false
      }
      return this.gigDetails !== this.formData.details ||
        this.gigNotes !== this.formData.notes ||
        this.gigTitle !== this.formData.title ||
        this.gigDate !== this.formData.date ||
        this.gigLocation.address !== this.formData.location.address ||
        this.gigLocation.latlng.lat !== this.formData.location.latlng.lat ||
        this.gigLocation.latlng.lng !== this.formData.location.latlng.lng
    },
    gigCollection() {
      return 'gigs/' + this.$store.state.user.uid + '/gigsof'
    },
    formattedDate() {
      return this.formData.date ? Intl.DateTimeFormat('en-gb', {
        weekday: 'long', day: 'numeric', month: 'long', year: 'numeric'
      }).format(new Date(this.formData.date)) : ''
    },
    ...mapGetters([
      'profileValid',
      'gigId',
      'gigTitle',
      'gigDate',
      'gigLocation',
      'gigDetails',
      'gigNotes',
      'gigProfilesWithOffer',
      'gigArchived',
    ])
  },
  watch: {
    formDataSaveable() {
      this.emitButtonSet(SAVE, this.formDataSaveable ? 'enable' : 'disable')
    },
    gigTitle() {
      this.formData.title = this.gigTitle
      eventBus.$emit('appAlterAppBar', { title: this.gigTitle })
    },
    gigDate() {
      this.formData.date = this.gigDate
    },
    gigLocation() {
      this.formData.location = this.gigLocation
    },
    gigDetails() {
      this.formData.details = this.gigDetails
    },
    gigNotes() {
      this.formData.notes = this.gigNotes
    },
    gigArchived() {
      this.setAppButtons()
    },
  },
  methods: {
    setLocation(location) {
      if (location) {
        this.formData.location = location
      } else {
        this.formData.location = {
          name: '', address: '', latlng: { lat: null, lng: null }
        }
      }
    },
    initFormData() {
      this.formData = {
        title: this.gigTitle,
        date: this.gigDate,
        location: {
          name: this.gigLocation.name,
          address: this.gigLocation.address,
          latlng: {
            lat: this.gigLocation.latlng.lat,
            lng: this.gigLocation.latlng.lng
          }
        },
        details: this.gigDetails,
        notes: this.gigNotes
      }
    },
    async dbSaveFormData() {
      if (this.formDataSaveable) {
        this.emitButtonSet(SAVE, 'pending')
        try {
          // Write to database
          if (this.gigId) {
            await updateDoc(
              doc(fbfs, this.gigCollection, this.gigId),
              { ...this.formData }
            )
          } else {
            const ref = await addDoc(
              collection(fbfs, this.gigCollection),
              { ...this.formData, archived: false }
            )
            this.$router.push({ name: 'gigeditordetails', params: { id: ref.id } })
          }
        } catch (e) {
          eventBus.$emit('appWarningSet', { message: 'Save failed: ' + e })
        }
        this.emitButtonSet(SAVE, 'finished')
      }
    },
    archiveGig() {
      this.showArchiveDialog = false
      if (this.gigId) {
        this.dbArchiveGig(this.gigId)
      }
    },
    async dbArchiveGig(id) {
      this.emitButtonSet(ARCHIVE, 'pending')
      try {
        await updateDoc(doc(fbfs, this.gigCollection, id), { archived: true })
      } catch (e) {
        eventBus.$emit('appWarningSet', { message: 'Archiving failed: ' + e })
      }
      this.emitButtonSet(ARCHIVE, 'finished')
    },
    handleAppButtonClick(button) {
      if (button === SAVE) {
        this.dbSaveFormData()
      } else if (button === ARCHIVE) {
        this.showArchiveDialog = true
      }
    },
    emitButtonSet(button, action) {
      eventBus.$emit('appButtonSet', button, action)
    },
    setAppButtons() {
      if (!this.gigArchived) {
        eventBus.$emit('appAlterAppBar', {
          buttons: [
            { show: true, icon: 'mdi-check-circle', disabled: !this.formDataSaveable, loading: false },
            { show: true, icon: 'mdi-archive', disabled: !this.gigId, loading: false },
            { show: false }
          ]
        })
      } else {
        eventBus.$emit('appAlterAppBar', {
          buttons: [ { show: false }, { show: false }, { show: false } ]
        })
      }
    },
    warnReadonly() {
      if (this.gigFrozen) {
        eventBus.$emit('appWarningSet', {
          color: 'warning',
          message: 'You can\'t change this field after an offer has been sent'
        })
      }
    }
  },
  beforeRouteLeave(to, from, next) {
    if (this.leaveRoute || !this.formDataSaveable) {
      next()
    } else {
      this.leaveRoute = to
      this.showLeaveDialog = true
    }
  },
  created() {
    eventBus.$on('appButtonClick', this.handleAppButtonClick)
    this.setAppButtons()
    this.initFormData()
  },
  beforeDestroy() {
    eventBus.$off('appButtonClick', this.handleAppButtonClick)
  }
}
</script>

<style scoped>
.text-formatted ::v-deep(h1) {
  font-size: 1.5rem;
  font-weight: 400;
  line-height: 2rem;
  letter-spacing: normal;
  font-family: "Roboto", sans-serif;
}
.text-formatted ::v-deep(h2) {
  font-size: 1.25rem;
  font-weight: 500;
  line-height: 2rem;
  letter-spacing: 0.0125em;
  font-family: "Roboto", sans-serif;
}
.text-formatted ::v-deep(p) {
  font-size: 1rem;
  font-weight: normal;
  line-height: 1.75rem;
  letter-spacing: 0.009375em;
  font-family: "Roboto", sans-serif;
}
.text-formatted ::v-deep(.ProseMirror) {
  margin: 12px 0 0 !important;
}
</style>