<template>
  <div>
    <tab-content v-if="!!offers">
      <v-row>
        <v-col cols="12" sm="8" offset-sm="2">
          <action-card>Select a card to see more details about the gig and the contact details of the sender.</action-card>
          <offer-card v-for="(o, n) in offers" :key="n"
            :offer="o"
            class="my-4">
          </offer-card>
        </v-col>
      </v-row>
    </tab-content>
  </div>
</template>

<script>
import OfferCard from '@/components/OfferCard.vue'
import TabContent from '../components/TabContent.vue'
import ActionCard from '../components/ActionCard.vue'
import { eventBus } from '@/main'
import { fbfuncs } from '@/plugins/firebase'
import { httpsCallable } from 'firebase/functions'

export default {
  name: 'OffersView',
  components: { OfferCard, TabContent, ActionCard },
  data() {
    return {
      offers: null
    }
  },
  methods: {
    handleCardButtonClick(id, action) {
      if (action === 'viewOffer') {
        this.$router.push({ name: 'offerview', params: { id: id } })
      }
    }
  },
  created() {
    eventBus.$on('cardButtonClick', this.handleCardButtonClick)
    eventBus.$emit('appTabsSet', null)
    eventBus.$emit('appAlterAppBar', {
      alter: false,
      title: 'Offers',
      buttons: [ { show: false }, { show: false }, { show: false } ],
      loading: true
    })

    const viewMyOffers = httpsCallable(fbfuncs, 'viewMyOffers')
    viewMyOffers()
      .then((result) => {
        this.offers = result.data
        eventBus.$emit('appAlterAppBar', { loading: false })
      })
      .catch((e) => {
        eventBus.$emit('appWarningSet', { message: 'Error: ' + e })
        eventBus.$emit('appAlterAppBar', { loading: false })
      })
  },
  beforeDestroy() {
    eventBus.$off('cardButtonClick', this.handleCardButtonClick)
  }
}
</script>
