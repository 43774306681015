<template>
  <v-row>
    <v-col cols="12">
      <google-places-service 
        @focus="$emit('focus')"
        @selected="selectLocation"
        :default-search="location ? location.address : ''"
        :disabled="disabled" :readonly="readonly"
      ></google-places-service>
    </v-col>
    <v-col v-if="latlngValid" cols="12" class="px-8">
      <google-static-map :location="location"></google-static-map>
    </v-col>
  </v-row>
</template>

<script>
import GooglePlacesService from './GooglePlacesService.vue'
import GoogleStaticMap from './GoogleStaticMap.vue'

export default {
  components: { GooglePlacesService, GoogleStaticMap },
  props: {
    initialLocation: {
      type: Object,
      default: null
    },
    disabled: {
      type: Boolean,
      default: false
    },
    readonly: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      location: this.initialLocation
    }
  },
  computed: {
    latlngValid() {
      return this.location.latlng.lat !== null &&
        this.location.latlng.lng !== null
    }
  },
  watch: {
    initialLocation() {
      this.location = this.initialLocation
    }
  },
  methods: {
    selectLocation(location) {
      this.location = location
      this.$emit('selected', location)
    }
  }
}
</script>

<style>

</style>