import Vue from 'vue'
import Vuex from 'vuex'

import skills from './skills'
import gigs from './gigs'
import offers from './offers'
import subs from './subs'

Vue.use(Vuex)

const store = new Vuex.Store({
  modules: {
    skills,
    gigs,
    offers,
    subs
  },
  state: {
    user: null,
    profile: null,
    account: null,
    areaCount: null,
    enableHelpCards: true
  },
  getters: {
    frontendVersion() {
      return '1.18'
    },
    areaCount(state) {
      return state.areaCount
    },
    maxAreaCount(state, getters) {
      if (getters.accountSubscribed) {
        return 8
      } else {
        return 4
      }
    },
    emailVerified(state) {
      if (state.user) {
        return state.user.emailVerified
      } else {
        return false
      }
    },
    profileName(state) {
      if (state.profile) {
        return state.profile.name
      } else {
        return null
      }
    },
    profileNameValid(state) {
      if (state.profile) {
        return !!state.profile.name && state.profile.name.length >= 4 && state.profile.name.length <= 40
      } else {
        return false
      }
    },
    profileIntro(state) {
      if (state.profile) {
        return state.profile.introduction
      } else {
        return null
      }
    },
    profileIntroValid(state) {
      if (state.profile) {
        return !!state.profile.introduction && state.profile.introduction.length >= 20 && state.profile.introduction.length <= 200
      } else {
        return false
      }
    },
    profileResume(state) {
      if (state.profile) {
        return state.profile.resume
      } else {
        return null
      }
    },
    profilePhotos(state) {
      if (state.profile) {
        return state.profile.photos
      } else {
        return null
      }
    },
    profileFeaturePhotos(state, getters) {
      const ret = []
      for (let i=0; i < 4; i++) {
        if (getters.profilePhotos && getters.profilePhotos[`feature${i}`]) {
          ret.push(getters.profilePhotos[`feature${i}`])
        } else {
          ret.push(null)
        }
      }
      return ret
    },
    profileAvatar(state) {
      if (state.profile) {
        return state.profile.avatar
      } else {
        return null
      }
    },
    profileAvatarValid(state) {
      if (state.profile) {
        return !!state.profile.avatar
      } else {
        return false
      }
    },
    profileValid(state, getters) {
      return getters.profileNameValid && getters.profileIntroValid && getters.profileAvatarValid
    },
    profileSkills(state) {
      try {
        return state.profile.skills || []
      } catch (e) {
        return []
      }
    },
    profileSkillCount(state) {
      try {
        return state.profile.skills.length
      } catch (e) {
        return 0
      }
    },
    accountSubscribed(state) {
      try {
        return !!state.account.subscribed
      } catch (e) {
        return false
      }
    },
    photoUrl: () => (id, photo) => {
      return photo ?
        `https://firebasestorage.googleapis.com/v0/b/map-a-muso.appspot.com/o/profiles%2F${id}%2F${photo}?alt=media` :
        'https://firebasestorage.googleapis.com/v0/b/map-a-muso.appspot.com/o/profiles%2Fdefault%2Favatar.jpg?alt=media&token=af757ab1-ff44-45bc-850c-76fb60e6dc75'
    }
  },
  mutations: {
    // User
    setUser(state, payload) {
      state.user = payload
      console.log('User:', state.user)
    },
    setProfile(state, payload) {
      state.profile = payload
    },
    setAccount(state, payload) {
      if (payload) {
        state.account = { subscribed: payload.subscribed }
      } else {
        state.account = null
      }
    },
    // Areas
    setAreaCount(state, count) {
      state.areaCount = count
    },
    // Settings
    setEnableHelpCards(state, payload) {
      state.enableHelpCards = payload
    },
    // All
    resetAll(state) {
      state.user = null
      state.profile = null
      state.account = null
      state.areaCount = null
    }
  }
})

export default store


import { onAuthStateChanged } from 'firebase/auth'
import { fbauth } from '@/plugins/firebase'
import { fbfs } from '@/plugins/firebase'
import { collection, doc, addDoc, updateDoc, query, onSnapshot, serverTimestamp } from 'firebase/firestore'

let dbUnsubscribeProfileChanges = null
let dbUnsubscribeAccountChanges = null
let dbUnsubscribeAreaChanges = null
let dbUnsubscribeGigChanges = null


onAuthStateChanged(fbauth, async (user) => {

  store.commit('setUser', user)

  // Subcribe to profile data changes
  if (dbUnsubscribeProfileChanges) {
    dbUnsubscribeProfileChanges()
    dbUnsubscribeProfileChanges = null
  }
  if (store.state.user) {
    const docRef = doc(fbfs, 'profiles', store.state.user.uid)
    dbUnsubscribeProfileChanges = onSnapshot(docRef, (doc) => {
      const p = doc.data()
      if (p) {
        const q = {
          name: p.name,
          introduction: p.introduction,
          viewable: p.viewable,
          photos: p.photos
        }
        q.resume = p.resume || ''
        q.skills = p.skills || []
        try {
          q.avatar = p.photos.avatar
        } catch (e) {
          q.avatar = null
        }
        store.commit('setProfile', q)
      }
    })
  }

  // Subcribe to account data changes
  if (dbUnsubscribeAccountChanges) {
    dbUnsubscribeAccountChanges()
    dbUnsubscribeAccountChanges = null
  }
  if (store.state.user) {
    const docRef = doc(fbfs, 'accounts', store.state.user.uid)
    dbUnsubscribeAccountChanges = onSnapshot(docRef, (doc) => {
      store.commit('setAccount', doc.data())
    })
  }

  // Subscribe to area changes
  if (dbUnsubscribeAreaChanges) {
    dbUnsubscribeAreaChanges()
    dbUnsubscribeAreaChanges = null
  }
  if (store.state.user) {
    const areaColl = collection(fbfs, `areas/${store.state.user.uid}/areasof`)
    dbUnsubscribeAreaChanges = onSnapshot(areaColl, async (snapshot) => {
      let count = 0
      snapshot.forEach(() => {
          count += 1
      });
      store.commit('setAreaCount', count)
    })
  }

  // Subscribe to gig changes
  if (dbUnsubscribeGigChanges) {
    dbUnsubscribeGigChanges()
    dbUnsubscribeGigChanges = null
  }
  if (store.state.user) {
    const gigQuery = query(
      collection(fbfs, `gigs/${store.state.user.uid}/gigsof`)
    )
    dbUnsubscribeGigChanges = onSnapshot(gigQuery, (snapshot) => {
      snapshot.docChanges().forEach((change) => {
        if (change.type === 'added' || change.type === 'modified') {
          store.commit('updateGigList', { id: change.doc.id, data: change.doc.data() })
        }
        if (change.type === 'removed') {
          store.commit('updateGigList', { id: change.doc.id, data: null })
        }
      })
    })
  }

  if (user) {
    await addDoc(collection(fbfs, `logs/${user.uid}/logsof`), {
      type: 'info', event: 'signIn', user: user.uid, timestamp: serverTimestamp(),
      fev: store.getters.frontendVersion
    })
    if (user.emailVerified) {
      await updateDoc(doc(fbfs, 'accounts', user.uid), { email: user.email})
    }
  } else {
    store.commit('resetAll')
    store.commit('resetGig')
    store.commit('deleteGigList')
  }
})
