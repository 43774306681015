<template>
  <div>
    <tab-title>Account</tab-title>
    <tab-content>
      <email-field></email-field>
    </tab-content>
  </div>
</template>

<script>
import TabTitle from '@/components/TabTitle.vue'
import TabContent from '@/components/TabContent.vue'
import EmailField from '@/components/EmailField.vue'

export default {
  name: 'AccountConfigTab',
  components: { TabTitle, TabContent, EmailField },
}
</script>