<template>
  <div>
    <tab-title>Biog</tab-title>
    <tab-content>
      <v-row>
        <v-col cols="12" md="8" offset-md="2">
          <action-card class="mb-4">Create a brief biog that details your professional background. This will be shown to the fixer as part of their search. We recommend using a device with a bigger screen for this step.</action-card>
          <tiptap-vuetify id="resume"
            v-model="resumeInput"
            :extensions="editorExtensions"
            :disabled="!emailVerified || !profileValid"
            :toolbar-attributes="{color: 'primarylight onprimarylight--text'}"
            :card-props="{flat:true, outlined: true, color: 'white'}"
            min-height="400" max-height="600"
          />
          <v-card flat class="d-flex justify-end mt-4">
            <v-btn
              @click="dbSaveResume()"
              :disabled="!resumeInputSaveable"
              color="primarylight onprimarylight--text"
            >
              Save Biog
            </v-btn>
          </v-card>
        </v-col>
      </v-row>
    </tab-content>

    <!-- Save dialog -->
    <v-dialog v-model="showSaveDialog" persistent max-width="400px">
      <v-card>
        <v-card-title class="primarydark--text">
          Do you want to leave this page?
        </v-card-title>
        <v-card-text>
          You have unsaved changes in your biog. If you leave this page you will loose your changes.
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text color="primarydark" @click="showSaveDialog=false; leaveRoute=null">
            Cancel
          </v-btn>
          <v-btn text color="primarydark" @click="showSaveDialog=false; $router.push(leaveRoute);">
            Leave
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { eventBus } from '@/main'
import TabTitle from '@/components/TabTitle.vue'
import TabContent from '@/components/TabContent.vue'
import ActionCard from '@/components/ActionCard.vue'
import { TiptapVuetify, Heading, Bold, Italic, Underline, BulletList, ListItem } from 'tiptap-vuetify'
import { fbfs } from '@/plugins/firebase'
import { doc, setDoc } from "firebase/firestore"
import { mapGetters } from 'vuex'

export default {
  name: 'ProfileResumeTab',
  components: { TabTitle, TabContent, ActionCard, TiptapVuetify },
  data() {
    return {
      resumeInput: null,
      editorExtensions: [
        [Heading, {options: {levels: [1, 2]}}],
        Bold, Italic, Underline, BulletList, ListItem,
      ],
      showSaveDialog: false,
      leaveRoute: null
    }
  },
  computed: {
    resumeInputSaveable() {
      return this.profileResume !== this.resumeInput
    },
    ...mapGetters([
      'emailVerified',
      'profileResume',
      'profileValid'
    ])
  },
  methods: {
    async dbSaveResume() {
      if (!this.resumeInputSaveable) {
        return
      }
      eventBus.$emit('appAlterAppBar', { loading: true })
      try {
        await setDoc(
          doc(fbfs, 'profiles', this.$store.state.user.uid),
          { resume: this.resumeInput }, { merge: true }
        )
      } catch (e) {
        eventBus.$emit('appWarningSet', { message: 'Saving resume failed: ' + e })
      }
      eventBus.$emit('appAlterAppBar', { loading: false })
    }
  },
  beforeRouteLeave(to, from, next) {
    if (this.leaveRoute || !this.resumeInputSaveable) {
      next()
    } else {
      this.leaveRoute = to
      this.showSaveDialog = true
    }
  },
  async created() {
    this.resumeInput = this.profileResume
  }
}
</script>

<style scoped>
#resume ::v-deep(h1) {
  font-size: 1.5rem;
  font-weight: 400;
  line-height: 2rem;
  letter-spacing: normal;
  font-family: "Roboto", sans-serif;
  margin-top: 24px !important;
  margin-bottom: 8px !important;
}
#resume ::v-deep(h2) {
  font-size: 1.25rem;
  font-weight: 500;
  line-height: 2rem;
  letter-spacing: 0.0125em;
  font-family: "Roboto", sans-serif;
  margin-top: 20px !important;
  margin-bottom: 8px !important;
}
#resume ::v-deep(p) {
  font-size: 1rem;
  font-weight: normal;
  line-height: 1.5rem;
  letter-spacing: 0.009375em;
  font-family: "Roboto", sans-serif;
  margin-top: 0px !important;
  margin-bottom: 8px !important;
}
#resume ::v-deep(.ProseMirror) {
  margin: 12px 0 0 !important;
}
</style>