<template>
  <div>
    <v-text-field v-model="searchText"
      :label="label"
      clearable @click:clear="reset()"
      :disabled="disabled"
      :loading="loadingColor"
      :prepend-icon="icon ? 'mdi-magnify' : null"
      hint="Search for an instrument, voice type or other skill"
      persistent-hint single-line
    ></v-text-field>
    <v-list v-for="(s, i) in filteredSkills" :key="i" class="py-0">
      <v-list-item v-if="s.tag" @click="selectItem(s)" two-line>
        <v-list-item-content>
          <v-list-item-title>{{ s.name }}</v-list-item-title>
          <v-list-item-subtitle>{{ s.tag }}</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
      <v-list-item v-else @click="selectItem(s)">
        <v-list-item-content>
          <v-list-item-title>{{ s.name }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </div>
</template>

<script>
import { eventBus } from '@/main'

export default {
  props: {
    label: {
      type: String,
      default: 'Search'
    },
    disabled: {
      type: Boolean,
      default: false
    },
    icon: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      searchText: ''
    }
  },
  computed: {
    filteredSkills() {
      return this.$store.getters.skillsFilter(this.searchText)
    },
    loadingColor() {
      return this.loading ? 'secondary' : false
    }
  },
  methods: {
    selectItem(item) {
      this.searchText = ''
      this.$emit('skillSelect', item.code)
    },
    reset() {
      this.searchText = ''
    }
  },
  created() {
    eventBus.$on('panelReset', this.reset)
  },
  beforeDestroy() {
    eventBus.$off('panelReset', this.reset)
  }
}
</script>

<style>

</style>