<template>
  <router-view></router-view>
</template>

<script>
import { eventBus } from '@/main'

export default {
  name: 'ProfileEditorView',
  async created() {
    eventBus.$emit('appAlterAppBar', {
      alter: false,
      title: 'Profile',
      buttons: [{ show: false }, { show: false }, { show: false }]
    })
    eventBus.$emit('appTabsSet', [
      { route: '/profile/info', icon: 'mdi-account' },
      { route: '/profile/biog', icon: 'mdi-text-box' },
      { route: '/profile/photos', icon: 'mdi-image' },
      { route: '/profile/skills', icon: 'mdi-music-clef-treble' },
      { route: '/profile/areas', icon: 'mdi-map-marker' }
    ])
  }
}
</script>
