const mutations = {
  setPromoApplied(state, payload) {
    state.promoApplied = payload
  }
}

const state = {
  promoApplied: null
}

export default {
  state,
  mutations
}
