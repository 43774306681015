/* Lat-Long mesh
  Effect of 1km movement in (lat, lng)
    At lat 50°: (0.01394°, 0.00898°)
    At lat 52°: (0.01455°, 0.00898°)
    At lat 57°: (0.01644°, 0.00897°)

    x = (lng + 11) / 0.01455
    y = (lat - 49) / 0.00898
 */

export function latlngToMesh(latlng) {
  return {
    x: Math.floor((latlng.lng + 11) / 0.01455),
    y: Math.floor((latlng.lat - 49) / 0.00898)
  }
}

export function meshToLatlng(mesh) {
  return {
    lat: mesh.y * 0.00898 + 49,
    lng: mesh.x * 0.01455 - 11
  }
}

export function meshString(mesh) {
  if (mesh.x >= 0 && mesh.x <= 9999 &&
      mesh.y >= 0 && mesh.y <= 9999) {
    const xs = mesh.x.toString().padStart(4, '0')
    const ys = mesh.y.toString().padStart(4, '0')
    return `x${xs}y${ys}`
  }
  return null
}

function areaCornersLatlngByTileSize(tileSize, mesh) {
  const cx = Math.floor(mesh.x / tileSize) * tileSize
  const cy = Math.floor(mesh.y / tileSize) * tileSize
  return [
    meshToLatlng({ x: cx - 2 * tileSize, y: cy + 3 * tileSize }),
    meshToLatlng({ x: cx + 3 * tileSize, y: cy + 3 * tileSize }),
    meshToLatlng({ x: cx + 3 * tileSize, y: cy - 2 * tileSize }),
    meshToLatlng({ x: cx - 2 * tileSize, y: cy - 2 * tileSize })
  ]
}

const tileSize = {
  'T02': 2,
  'T05': 5,
  'T08': 8,
  'T16': 16,
  'T24': 24
}

export function areaCornersLatlng(tileCode, mesh) {
  return areaCornersLatlngByTileSize(tileSize[tileCode], mesh)
}
