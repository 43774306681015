<template>
  <div>
    <v-text-field v-model="searchText"
      :label="label"
      @keyup.enter="doSearch"
      clearable @click:clear="clearSearch"
      :disabled="disabled"
      hint="Search for the name or postcode of a place in the UK"
      persistent-hint single-line>
      <template v-slot:prepend>
        <v-btn @click="doSearch" icon color="primary">
          <v-icon>mdi-magnify</v-icon>
        </v-btn>
      </template>
    </v-text-field>
    <v-list v-for="(s, i) in searchResults" v-bind:key="i" class="py-0">
      <v-list-item v-on:click="selectItem(i)">
        <v-list-item-title v-text="s.name"></v-list-item-title>
      </v-list-item>
    </v-list>
  </div>
</template>

<script>
import { eventBus } from '@/main'

export default {
  props: {
    label: {
      type: String,
      default: 'Name or postcode of place'
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      geocoder: null,
      searchText: '',
      searchResults: [],
    }
  },
  computed: {
  },
  methods: {
    doSearch() {
      console.log('doSearch ' + this.searchText)

      this.searchResults = []

      if (!this.searchText) {
        return
      }

      this.searchText = this.searchText.trim()
      let searchObject = {
        address: this.searchText,
        componentRestrictions: { country: 'gb' }
      }

      this.geocoder.geocode(searchObject, (results, status) => {

        if (status === 'OK') {

          for (const result of results) {
            console.log(result)

            let wants = []

            if (result.types.includes('locality')) {
              wants = ['locality', 'administrative_area_level_2']
            } else if (result.types.includes('sublocality')) {
              wants = ['sublocality', 'postal_town', 'administrative_area_level_2']
            } else if (result.types.includes('neighborhood')) {
              wants = ['neighborhood', 'postal_town', 'administrative_area_level_2']
            } else if (result.types.includes('postal_code_prefix')) {
              wants = ['postal_town', 'administrative_area_level_2', 'postal_code_prefix']
            } else if (result.types.includes('postal_code')) {
              wants = ['route', 'locality', 'postal_code']
            } else {
              eventBus.$emit('appWarningSet', { message: 'Search for a UK town or a postcode' })
              continue
            }

            let address = ''

            for (let w of wants) {
              for (let component of result.address_components) {
                if (component.types.includes(w)) {
                  if (address.length > 0) {
                    address += ', '
                  }
                  address += component.long_name
                }
              }
            }

            this.searchResults.push({
              valid: true,
              name: address,
              latlng: {
                lat: result.geometry.location.lat(),
                lng: result.geometry.location.lng()
              }
            })
          }
        }
        else {
          this.$emit('selected', { valid: false })

          if (status === 'ZERO_RESULTS') {
            eventBus.$emit('appWarningSet', { color: 'warning', message: 'Couldn\'t find that place' })
          } else {
            eventBus.$emit('appWarningSet', { message: 'Error: ' + status })
          }
        }
      })
    },
    clearSearch() {
      this.searchResults = []
    },
    selectItem(index) {
      this.$emit('selected', this.searchResults[index])
      this.searchText = ''
      this.searchResults = []
    },
    resetForms() {
      this.searchText = ''
      this.searchResults = []
    }
  },
  created() {
    eventBus.$on('panelReset', this.resetForms)
  },
  mounted() {
    this.geocoder = new window.google.maps.Geocoder();
  },
  beforeDestroy() {
    eventBus.$off('panelReset', this.resetForms)
  }
}
</script>

<style>

</style>