<template>
  <div>
    <tab-title>Offers Sent {{ tabTitleNote }}</tab-title>
    <tab-content>
      <v-row>
        <v-col cols="12" sm="8" offset-sm="2">
          <action-card class="mb-4">Keep track of the offers you have sent and whether or not they have been seen.</action-card>
          <profile-card v-for="p in gigProfilesWithOffer" :key="p.id"
            :mode="gigArchived?'archived':'offer'" :profile="p"
            class="my-3">
          </profile-card>
        </v-col>
      </v-row>
    </tab-content>
  </div>
</template>

<script>
import TabTitle from '@/components/TabTitle.vue'
import TabContent from '@/components/TabContent.vue'
import ActionCard from '@/components/ActionCard.vue'
import ProfileCard from '@/components/ProfileCard.vue'
import { eventBus } from '@/main'
import { mapGetters } from 'vuex'

export default {
  name: 'GigEditorOffersTab',
  components: { TabTitle, TabContent, ActionCard, ProfileCard },
  data() {
    return {
    }
  },
  computed: {
    tabTitleNote() {
      return this.gigArchived ? '(ARCHIVED)' : ''
    },
    ...mapGetters([
      'gigArchived',
      'gigProfilesWithOffer',
    ])
  },
  created() {
    eventBus.$emit('appAlterAppBar', {
      buttons: [ { show: false }, { show: false }, { show: false } ]
    })
  }
}
</script>