<template>
  <div>
    <tab-title>Location</tab-title>
    <tab-content>
      <google-static-map v-if="!offerBarred" :location="offer.gig.location"></google-static-map>
    </tab-content>
  </div>
</template>

<script>
import TabTitle from '@/components/TabTitle.vue'
import TabContent from '@/components/TabContent.vue'
import GoogleStaticMap from '@/components/GoogleStaticMap.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'OfferLocationTab',
  components: { TabTitle, TabContent, GoogleStaticMap },
  computed: {
    ...mapGetters([
      'offer',
      'offerBarred'
    ])
  }
}
</script>
