<template>
  <div>
    <template v-if="!off">
      <!-- Passive card -->
      <v-card v-if="passive" tile outlined :class="cardclass">
        <v-card-title v-if="titleTextAndClose" :class="cardcompclass">
          {{ title }}
          <v-spacer></v-spacer>
          <v-icon v-if="closeable" @click="onClose">mdi-close</v-icon>
        </v-card-title>
        <v-card-title v-if="titleTextOnly" :class="cardcompclass">
          {{ title }}
        </v-card-title>
        <v-card-title v-if="titleCloseOnly" :class="cardcompclass">
          <v-spacer></v-spacer>
          <v-icon v-if="closeable" @click="onClose">mdi-close</v-icon>
        </v-card-title>
        <v-card-text :class="cardcompclass">
          <div class="d-flex flex-no-wrap justify-space-between align-center">
            <div :class="textclass">
              <slot></slot>
            </div>
            <div v-if="iconname">
              <v-icon :large="large" :color="color" class="ml-2">{{ iconname }}</v-icon>
            </div>
          </div>
        </v-card-text>
        <!-- <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn icon>
            <v-icon>mdi-heart</v-icon>
          </v-btn>
        </v-card-actions> -->
      </v-card>

      <!-- Active card -->
      <v-card v-else @click="onClick" tile :class="cardclass">
        <v-card-title v-if="titleTextAndClose" :class="cardcompclass">
          {{ title }}
          <v-spacer></v-spacer>
          <v-icon v-if="closeable" @click="onClose">mdi-close</v-icon>
        </v-card-title>
        <v-card-title v-if="titleTextOnly" :class="cardcompclass">
          {{ title }}
        </v-card-title>
        <v-card-title v-if="titleCloseOnly" :class="cardcompclass">
          <v-spacer></v-spacer>
          <v-icon v-if="closeable" @click="onClose">mdi-close</v-icon>
        </v-card-title>
        <v-card-text :class="cardcompclass">
          <div class="d-flex flex-no-wrap justify-space-between align-center">
            <div :class="textclass">
              <slot></slot>
            </div>
            <div v-if="iconname">
              <v-icon :large="large" :color="color" class="ml-2">{{ iconname }}</v-icon>
            </div>
          </div>
        </v-card-text>
      </v-card>
    </template>
  </div>
</template>

<script>
export default {
  props: {
    clickable: {
      type: Boolean,
      default: false
    },
    closeable: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: 'help'
    },
    title: {
      type: String,
      default: null
    },
    noicon: {
      type: Boolean,
      default: false
    },
    icon: {
      type: String,
      default: null
    },
    large: {
      type: Boolean,
      default: false
    },
    toRoute: {
      type: String,
      default: null
    },
  },
  data() {
    return {
    }
  },
  computed: {
    off() {
      if (this.type === 'help' && !this.$store.state.enableHelpCards) {
        return true
      } else {
        return false
      }
    },
    passive() {
      return !this.clickable && !this.toRoute
    },
    titleTextAndClose() {
      return this.title && this.closeable
    },
    titleTextOnly() {
      return this.title && !this.closeable
    },
    titleCloseOnly() {
      return !this.title && this.closeable
    },
    iconname() {
      if (this.noicon) {
        return null
      }
      if (this.icon) {
        return this.icon
      } else if (this.type === 'note') {
        return null
      } else if (this.type === 'info') {
        return 'mdi-information'
      } else if (this.type === 'warning') {
        return 'mdi-alert'
      } else if (this.type === 'help') {
        return 'mdi-book-open-variant'
      } else {
        return null
      }
    },
    textclass() {
      if (this.type === 'help') {
        return 'caption'
      } else {
        return 'body-2'
      }
    },
    cardclass() {
      if (this.toRoute) {
        return 'routing-card pa-2'
      } else if (this.type === 'info' || this.type === 'note') {
        return 'info-card pa-2'
      } else if (this.type === 'warning') {
        return 'warning-card pa-2'
      } else if (this.type === 'help') {
        return 'help-card pa-2'
      } else {
        return ''
      }
    },
    cardcompclass() {
      return 'pa-1'
    },
    color() {
      if (this.type === 'warning') {
        return 'error'
      } else {
        return 'secondary'
      }
    }
  },
  methods: {
    onClick() {
      if (this.toRoute) {
        this.$router.push({ name: this.toRoute })
      }
      if (this.clickable) {
        this.$emit('click')
      }
    },
    onClose() {
      this.$emit('close')
    }
  }
}
</script>

<style scoped>
.help-card {
  border-color: #FF6D00;
}

.info-card {
  border-color: #FF6D00;
}

.warning-card {
  background-color: #FFF59D;
  border-color: #D50000;
  color: #424242;
  border-width: 1px;
}
.routing-card {
  background-color: #B8D8D8;
  color: #3B494C;
}
</style>