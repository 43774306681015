<template>
  <div>
    <v-file-input
      v-model.trim="fileName"
      @change="dbUploadPhoto()"
      :rules="inputRules"
      :loading="uploadingColor"
      :disabled="uploadingDisabled"
      accept="image/png, image/jpeg"
      :label="label"
      placeholder="Choose a photo"
      prepend-icon="mdi-camera"
    >
      <template v-slot:append-outer>
        <v-btn
          v-if="allowDelete"
          @click="dbDeletePhoto()"
          :disabled="profileFeaturePhotos[index] === null"
          icon color="primary" class="ml-4">
          <v-icon>mdi-delete</v-icon>
        </v-btn>
      </template>
    </v-file-input>
  </div>
</template>

<script>
import { eventBus } from '@/main'
import { fbfs, fbstorage } from '@/plugins/firebase'
import { doc, updateDoc } from "firebase/firestore"
import { ref, uploadBytes } from "firebase/storage"
import { mapGetters } from 'vuex'

export default {
  props: {
    mode: {
      type: String,
      required: true
    },
    index: {
      type: Number,
      default: 0
    },
  },
  data() {
    return {
      fileName: null,
      photoName: null,
      inputRules: [ (v) => !v || v.size < 8000000 || 'Photo size should be less than 8 MB' ],
      uploading: false,
    }
  },
  computed: {
    allowDelete() {
      return this.mode === 'feature' ? true : false
    },
    label() {
      return this.mode === 'feature' ? 'Change photo #' + (this.index + 1) : 'Change photo'
    },
    uploadingDisabled() {
      return !!this.uploadingColor
    },
    uploadingColor() {
      return this.uploading ? 'secondary' : false
    },
    tag() {
      return this.mode === 'feature' ? `${this.mode}${this.index}` : this.mode
    },
    ...mapGetters([
      'profilePhotos',
      'profileFeaturePhotos'
    ])
  },
  watch: {
    profilePhotos() {
      try {
        if (this.profilePhotos[this.tag].includes(this.photoName)) {
          this.uploading = false
        }
      // eslint-disable-next-line no-empty
      } catch (e) {
      }
    }
  },
  methods: {
    async dbUploadPhoto() {
      try {
        // Upload photo
        if (this.fileName) {
          const n = String(Math.floor(Math.random() * 1000000)).padStart(6, '0')
          this.photoName = `${this.tag}_${n}.jpg`
          const photoRef = ref(fbstorage, `profiles/${this.$store.state.user.uid}/${this.photoName}`)
          await uploadBytes(photoRef, this.fileName)
          this.fileName = null
          this.uploading = true
        }
      } catch (e) {
        eventBus.$emit('appWarningSet', { message: 'Upload failed: ' + e })
      }
    },
    async dbDeletePhoto() {
      try {
        const profileRef = doc(fbfs, 'profiles', this.$store.state.user.uid)
        await updateDoc(profileRef, { [`photos.${this.tag}`]: null })
      } catch (e) {
        eventBus.$emit('appWarningSet', { message: 'Delete failed: ' + e })
      }
    }
  }
}
</script>
