<template>
  <router-view></router-view>
</template>

<script>
import { eventBus } from '@/main'

export default {
  name: 'SettingsView',
  created() {
    eventBus.$emit('appAlterAppBar', {
      alter: false,
      title: 'Settings',
      buttons: [ { show: false }, { show: false }, { show: false } ]
    })
    eventBus.$emit('appTabsSet', [
      { route: '/settings/config', icon: 'mdi-cog' },
      { route: '/settings/account', icon: 'mdi-account-cog' },
      { route: '/settings/subscription', icon: 'mdi-playlist-check' }
    ])
  }
}
</script>
