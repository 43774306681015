<template>
  <div>
    <google-geocoder v-if="showSearch" @selected="addArea($event)" label="Add new area" :disabled="disabled">
    </google-geocoder>

    <div :key="refreshListKey" class="pt-4">
      <v-card v-for="(area, id) in areas" :key="id" flat class="my-2">
        <div class="d-flex justify-space-between align-center">
          <div class="ma-2">
            <span class="text-subtitle-1">
              {{ area.name }}
            </span>
            <span class="text-body-2 font-weight-light">
              (Range: {{ RANGES[area.range].name }})
            </span>
          </div>
          <div style="white-space: nowrap;">
            <v-btn
              @click.stop="openEditor(id)"
              icon color="primary">
              <v-icon>mdi-map-marker-radius</v-icon>
            </v-btn>
            <v-btn
              @click.stop="deleteArea(id)"
              icon color="primary">
              <v-icon>mdi-delete</v-icon>
            </v-btn>
          </div>
        </div>
      </v-card>
      <v-card v-if="showMap" tile flat class="mt-4">
        <v-img aspect-ratio="1" :src="mapAllUrl"></v-img>
      </v-card>
    </div>

    <v-dialog v-model="showEditor" max-width="600px">
      <v-card>
        <v-card-title>
          {{ currentId !== null ? areas[currentId].name : '' }}
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12">
              <v-img aspect-ratio="1" :src="mapUrl"></v-img>
            </v-col>
            <v-col cols="12">
              <v-slider v-model="rangeIndex" class="align-self-stretch"
                min="1" max="5" ticks="always" tick-size="4"
                color="secondary" track-fill-color="primarydark" track-color="primarylight"
              >
                <template v-slot:prepend>
                  <v-icon @click="decrementRange()" color="secondary">mdi-minus</v-icon>
                </template>
                <template v-slot:append>
                  <v-icon @click="incrementRange()" color="secondary">mdi-plus</v-icon>
                </template>
              </v-slider>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="pb-4">
          <v-spacer></v-spacer>
          <v-btn
            @click="updateArea(); closeEditor()"
            color="primarylight onprimarylight--text">
            OK
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { eventBus } from '@/main'
import { meshString } from '@/utils/llmesh'
import GoogleGeocoder from './GoogleGeocoder.vue'
import { latlngToMesh, areaCornersLatlng } from '@/utils/llmesh'
import { fbfs } from '@/plugins/firebase'
import { collection, doc, setDoc, updateDoc, deleteDoc, onSnapshot } from "firebase/firestore"

export default {
  components: {
    'google-geocoder': GoogleGeocoder
  },
  props: {
    maxCount: {
      type: Number,
      default: 10
    },
    mapoff: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      areas: {},
      dbUnsubscribeChanges: null,
      refreshListKey: 1,
      currentId: null,
      rangeIndex: null,
      apiKey: 'AIzaSyDNnD3uOFdgO1z9MiZ5ZyD5DciQ0xYp8ag'
    }
  },
  computed: {
    showEditor: {
      get() {
        return this.currentId !== null
      },
      set(value) {
        if (value === false) {
          this.currentId = null
        }
      }
    },
    showSearch() {
      return this.areaCount < this.maxCount
    },
    showMap() {
      return !this.mapoff && this.areaCount > 0
    },
    coll() {
      return `areas/${this.$store.state.user.uid}/areasof`
    },
    areaIds() {
      return Object.keys(this.areas)
    },
    areaCount() {
      return Object.keys(this.areas).length
    },
    mapUrl() {
      if (this.currentId === null) {
        return ''
      }
      const area = this.areas[this.currentId]
      let p = 'path=fillcolor:0xFF000033|weight:0'
      for (const c of areaCornersLatlng(this.RANGES[this.rangeIndex].tiles, area.mesh)) {
        p += '|' + c.lat + ',' + c.lng
      }
      p += '&size=320x320&scale=2'
      return `https://maps.googleapis.com/maps/api/staticmap?${p}&key=${this.apiKey}`
    },
    mapAllUrl() {
      if (this.areaCount === 0) {
        return ''
      }
      let p = ''
      for (const aid of this.areaIds) {
        const area = this.areas[aid]
        p += 'path=fillcolor:0xFF000033|weight:0'
        for (const c of areaCornersLatlng(this.RANGES[area.range].tiles, area.mesh)) {
          p += '|' + c.lat + ',' + c.lng
        }
        p += '&'
      }
      p += `size=400x400&scale=2`
      return `https://maps.googleapis.com/maps/api/staticmap?${p}&key=${this.apiKey}`
    }
  },
  methods: {
    async addArea(area) {
      if (area.valid && this.areaCount < this.maxCount) {
        area.range = 2
        area.mesh = latlngToMesh(area.latlng)
        delete area.valid
        try {
          await setDoc(doc(fbfs, this.coll, meshString(area.mesh)), area)
        } catch (e) {
          eventBus.$emit('appWarningSet', { message: 'Save failed: ' + e })
        }
      }
    },
    async deleteArea(id) {
      try {
        await deleteDoc(doc(fbfs, this.coll, id))
      } catch (e) {
        eventBus.$emit('appWarningSet', { message: 'Delete failed: ' + e })
      }
    },
    async updateArea() {
      try {
        await updateDoc(
          doc(fbfs, this.coll, this.currentId),
          { range: this.rangeIndex }
        )
      } catch (e) {
        eventBus.$emit('appWarningSet', { message: 'Update failed: ' + e })
      }
    },
    openEditor(id) {
      this.rangeIndex = this.areas[id].range
      this.currentId = id
    },
    closeEditor() {
      this.currentId = null
    },
    decrementRange() {
      if (this.rangeIndex > 1) {
        this.rangeIndex -= 1
      }
    },
    incrementRange() {
      if (this.rangeIndex < 5) {
        this.rangeIndex += 1
      }
    },
  },
  created() {
    this.RANGES = [
      { }, // unused index
      { name:'XS', tiles:'T02' },
      { name:'S', tiles:'T05' },
      { name:'M', tiles:'T08' },
      { name:'L', tiles:'T16' },
      { name:'XL', tiles:'T24' },
    ]

    this.dbUnsubscribeChanges = onSnapshot(collection(fbfs, this.coll), (snapshot) => {
      this.areas = {}
      snapshot.forEach((doc) => {
        this.areas[doc.id] = doc.data()
      })
      this.refreshListKey += 1
    })
  },
  beforeDestroy() {
    if (this.dbUnsubscribeChanges) {
      this.dbUnsubscribeChanges()
    }
  }
}
</script>

<style>

</style>