<template>
  <tab-content>
    <v-row>
      <!-- App version -->
      <v-col v-if="reloadRequired" cols="12" sm="8" offset-sm="2">
        <action-card
          type="warning"
          title="Reload required"
        >
          <p>You are using an outdated version of the app. Press the reload button of your browser to get the latest version.</p>
        </action-card>
      </v-col>

      <!-- Notes from database -->
      <v-col v-for="(note, n) in notes" :key="n" cols="12" sm="8" offset-sm="2">

        <!-- Plain notes -->
        <action-card v-if="showPlainNote(note)"
          :type="note.type"
          :title="note.title"
          closeable @close="onCloseNote(note.id)"
        >
          <div v-if="note.text">{{ note.text }}</div>
          <div v-if="note.html" v-html="note.html"></div>
        </action-card>

        <!-- Profile Wizard -->
        <action-card v-if="showProfileWiz(note)"
          type="info" noicon
          title="Getting Started"
          closeable @close="onCloseNote(note.id)"
        >
          <profile-wiz @finished="hideNote(note.id)"></profile-wiz>
        </action-card>

        <!-- Shortcuts -->
        <action-card v-if="showShortcuts(note)"
          type="info" noicon
          title="Shortcuts"
          closeable @close="onCloseNote(note.id)"
        >
          <div class="text-subtitle-1 mt-2">For Performers</div>
          <div>
            <v-btn @click="$router.push({name:'profileskills'})" :color="buttonColor" class="mr-2 mb-1">
              My Skills
            </v-btn>
            <v-btn @click="$router.push({name:'profileareas'})" :color="buttonColor" class="mr-2 mb-1">
              My Areas
            </v-btn>
            <v-btn @click="$router.push({name:'profilebiog'})" :color="buttonColor" class="mr-2 mb-1">
              My Biog
            </v-btn>
          </div>
          <div class="text-subtitle-1 mt-2">For Fixers</div>
          <div>
            <v-btn @click="$router.push({path:'gigs/add/details'})" :color="buttonColor" class="mr-2 mb-1">
              New Gig
            </v-btn>
            <v-btn @click="$router.push({name:'gigsactive'})" :color="buttonColor" class="mr-2 mb-1">
              My Gigs
            </v-btn>
          </div>
        </action-card>
      </v-col>
    </v-row>
  </tab-content>
</template>

<!-- TODO: Further uses of dashboard notes
      Status, listed or not
      New offer arrived
      Statistics
      Discount codes to share
      News -->

<script>
import TabContent from '@/components/TabContent.vue'
import ActionCard from '@/components/ActionCard.vue'
import ProfileWiz from '@/components/ProfileWiz.vue'
import { eventBus } from '@/main'
import { mapGetters } from 'vuex'
import { fbfs, fbfuncs } from '@/plugins/firebase'
import { httpsCallable } from 'firebase/functions'
import { collection, doc, query, where, orderBy, getDocs, updateDoc, onSnapshot } from 'firebase/firestore'

export default {
  name: 'DashboardView',
  components: { TabContent, ActionCard, ProfileWiz },
  data() {
    return {
      notes: [],
      frontendVersionMin: '0.00'
    }
  },
  computed: {
    emailVerified() {
      if (this.$store.state.user) {
        return this.$store.state.user.emailVerified
      } else {
        return false
      }
    },
    reloadRequired() {
      return this.frontendVersionMin > this.frontendVersion
    },
    buttonColor() {
      return 'primarylight onprimarylight--text'
    },
    ...mapGetters([
      'frontendVersion'
    ])
  },
  methods: {
    async hideNote(id) {
      await updateDoc(
        doc(fbfs, 'dashboard', id),
        { show: false }
      )
    },
    async onCloseNote(id) {
      this.notes.forEach((note) => {
        if (note.id === id) {
          note.show = false
        }
      })
      await this.hideNote(id)
    },
    showPlainNote(note) {
      return (note.type === 'note' || note.type === 'info' || note.type === 'warning'|| note.type === 'help') && note.show
    },
    showProfileWiz(note) {
      return (note.type === 'profilewiz') && note.show
    },
    showShortcuts(note) {
      return (note.type === 'shortcuts') && note.show
    },
  },
  created() {
    eventBus.$emit('appTabsSet', null)
    eventBus.$emit('appAlterAppBar', {
      alter: false,
      title: 'Dashboard',
      buttons: [ { show: false }, { show: false }, { show: false } ],
      loading: true
    })
    // Get central config
    const centralConfig = httpsCallable(fbfuncs, 'centralConfig')
    centralConfig()
      .then((result) => {
        this.frontendVersionMin = result.data.frontendVersionMin
        console.log('fev', this.frontendVersion, 'fevMin', result.data.frontendVersionMin)
      })
      .catch((e) => {
        eventBus.$emit('appWarningSet', { message: 'Error: ' + e })
      })

    // Get dasboard notes
    const unsubscribe = onSnapshot(doc(fbfs, 'accounts', this.$store.state.user.uid), async (doc) => {
      if (doc.data()) {
        unsubscribe()
        const q = query(
          collection(fbfs, 'dashboard'),
          where('user', '==', this.$store.state.user.uid),
          where('show', '==', true),
          orderBy('order')
        )
        const snapshot = await getDocs(q)
        snapshot.forEach((doc) => {
          this.notes.push({ id: doc.id, ...doc.data() })
        })
        eventBus.$emit('appAlterAppBar', { loading: false })
      }
    })
  }
}
</script>

<style scoped>
.wiz-card {
  border-color: #FF6D00;
}
</style>
