<template>
  <div>
    <router-view></router-view>
  </div>
</template>

<script>
import { eventBus } from '@/main'
import { fbfuncs } from '@/plugins/firebase'
import { httpsCallable } from 'firebase/functions'

const BACK = 9

export default {
  name: 'OfferView',
  methods: {
    handleAppButtonClick(button) {
      if (button === BACK) {
        this.$router.push({ name: 'offers' })
      }
    }
  },
  created() {
    eventBus.$on('appButtonClick', this.handleAppButtonClick)
    eventBus.$emit('appAlterAppBar', {
      title: 'Offer',
      buttons: [ { show: false }, { show: false }, { show: false } ],
      loading: true
    })
    eventBus.$emit('appTabsSet', null)

    const id = this.$route.params.id

    const viewOffer = httpsCallable(fbfuncs, 'viewOffer')
    viewOffer({ id: id, fev: this.$store.getters.frontendVersion })
      .then((result) => {
        this.$store.commit('setOffer', result.data)
        eventBus.$emit('appAlterAppBar', {
          alter: true,
          icon: 'mdi-arrow-left',
          loading: false
        })
        eventBus.$emit('appTabsSet', [
          { route: { name: 'offerdetails' }, icon: 'mdi-music-clef-treble' },
          { route: { name: 'offersender' }, icon: 'mdi-account' },
          { route: { name: 'offerlocation' }, icon: 'mdi-map-marker' }
        ])
        this.$router.push({ name: 'offerdetails' })
      })
      .catch((e) => {
        eventBus.$emit('appWarningSet', { message: 'Error: ' + e })
        eventBus.$emit('appAlterAppBar', { loading: false })
        this.$router.push({ name: 'offers' })
      })
  },
  beforeDestroy() {
    eventBus.$off('appButtonClick', this.handleAppButtonClick)
    this.$store.commit('setOffer', null)
  }
}
</script>
