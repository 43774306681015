<template>
  <div>
    <v-text-field
      v-model.trim="input"
      @change="enterInput()"
      :loading="loadingColor"
      :rules="inputRules"
      label="Name"
      placeholder="You will be known by this name on this site"
    ></v-text-field>
    <v-btn v-if="savelabel" @click="onSaveClick()" :disabled="!inputValid" :color="savecolor" class="mr-2 mb-1">
      {{ savelabel }}
    </v-btn>
    <v-btn v-if="cancellabel" text @click="onCancelClick()" :color="cancelcolor" class="mr-2 mb-1">
      {{ cancellabel }}
    </v-btn>
  </div>
</template>

<script>
import { eventBus } from '@/main'
import { fbfs } from '@/plugins/firebase'
import { doc, setDoc } from "firebase/firestore"
import { mapGetters } from 'vuex'

export default {
  props: {
    savelabel: {
      type: String,
      default: null
    },
    cancellabel: {
      type: String,
      default: null
    },
    savecolor: {
      type: String,
      default: ''
    },
    cancelcolor: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      input: null,
      inputRules: [ () => (this.inputValid) || 'From 4 to 40 letters' ],
      loading: false,
    }
  },
  computed: {
    inputValid() {
      try {
        return this.input.length >= 4 && this.input.length <= 40
      } catch (e) {
        return false
      }
    },
    loadingColor() {
      return this.loading ? 'secondary' : false
    },
    ...mapGetters([
      'profileName',
      'profileNameValid',
    ])
  },
  watch: {
    profileName() {
      this.input = this.profileName
    }
  },
  methods: {
    async onSaveClick() {
      await this.enterInput()
      this.$emit('saveclick')
    },
    onCancelClick() {
      this.$emit('cancelclick')
    },
    async enterInput() {
      if (this.input === this.profileName || !this.inputValid) {
        return
      }
      this.loading = true
      try {
        await setDoc(
          doc(fbfs, 'profiles', this.$store.state.user.uid),
          { name: this.input },
          { merge: true }
        )
      } catch (e) {
        eventBus.$emit('appWarningSet', { message: 'Save failed: ' + e })
      }
      this.loading = false
    }
  },
  created() {
    this.input = this.profileName
  }
}
</script>

<style scoped>
.v-btn {
  margin-top: 12px;
}
</style>