<template>
  <div>
    <tab-content v-if="viewingProfile">
      <profile-viewer :id="viewingProfile" :offer-id="viewingProfileOfferId"></profile-viewer>
    </tab-content>
    <router-view v-else></router-view>
    <make-offer-dialog v-if="makeOfferDialog" :profile="offeringProfile" :gigId="gigId"></make-offer-dialog>
  </div>
</template>

<script>
import ProfileViewer from '@/components/ProfileViewer.vue'
import MakeOfferDialog from '@/components/MakeOfferDialog.vue'
import TabContent from '@/components/TabContent.vue'
import { eventBus } from '@/main'
import { mapGetters } from 'vuex'
import { fbfs } from '@/plugins/firebase'
import { doc, updateDoc, increment, deleteField } from 'firebase/firestore'

const BACK = 9

export default {
  components: { ProfileViewer, MakeOfferDialog, TabContent },
  data() {
    return {
      viewingProfile: null,
      offeringProfile: null,
    }
  },
  computed: {
    gigCollection() {
      return 'gigs/' + this.$store.state.user.uid + '/gigsof'
    },
    viewingProfileOfferId() {
      try {
        return this.gigProfiles[this.viewingProfile].offer.id
      } catch (e) {
        return null
      }
    },
    makeOfferDialog() {
      return !!this.gigShowMakeOffer
    },
    ...mapGetters([
      'profileValid',
      'gigId',
      'gigProfiles',
      'gigProfilesFound',
      'gigArchived',
      'gigShowMakeOffer'
    ])
  },
  watch: {
    viewingProfile() {
      this.setTabs(this.$route.params.id)
    },
  },
  methods: {
    handleAppButtonClick(button) {
      if (button === BACK) {
        if (this.viewingProfile) {
          this.viewingProfile = null
        } else {
          if (this.gigArchived) {
            this.$router.push({ name: 'gigsarchive' })
          } else {
            this.$router.push({ name: 'gigsactive' })
          }
        }
      }
    },
    async dbSelectProfile(id) {
      if (this.gigId) {
        const p = { ... this.gigProfiles[id], ... this.gigProfilesFound[id], selected: true }
        delete p.id
        delete p.tier
        try {
          const gigRef = doc(fbfs, this.gigCollection, this.gigId)
          await updateDoc(gigRef, {
            [`profiles.${id}`]: p
          })
          await updateDoc(doc(fbfs, 'counts', id), {
            'selected.total': increment(1)
          });
        } catch (e) {
          eventBus.$emit('appWarningSet', { message: 'Error: ' + e })
        }
      }
    },
    async dbUnselectProfile(id) {
      if (this.gigId) {
        const gigRef = doc(fbfs, this.gigCollection, this.gigId)
        // Check if offer has been sent
        if (this.gigProfiles[id].offer) {
          // Unselect profile at gig
          await updateDoc(gigRef, {
            [`profiles.${id}.selected`]: false
          })
        } else {
          // Delete profile at gig
          await updateDoc(gigRef, {
            [`profiles.${id}`]: deleteField()
          })
        }
      }
    },
    async handleCardButtonClick(profile, action) {
      if (action === 'selectProfile') {
        await this.dbSelectProfile(profile.id)
      } else if (action === 'unselectProfile') {
        await this.dbUnselectProfile(profile.id)
      } else if (action === 'viewProfile') {
        this.viewingProfile = profile.id
      } else if (action === 'messageProfile') {
        this.offeringProfile = profile
        this.$store.commit('gigShowMakeOffer', true)
      }
    },
    emitButtonSet(button, action) {
      eventBus.$emit('appButtonSet', button, action)
    },
    setTabs(id) {
      if (this.viewingProfile) {
        eventBus.$emit('appTabsSet', null)
      } else if (this.gigArchived) {
        eventBus.$emit('appTabsSet', [
          { route: { name: 'gigeditordetails', params: { id: id } }, icon: 'mdi-music' },
          { route: { name: 'gigeditorshortlist', params: { id: id } }, icon: 'mdi-bookmark-multiple' },
          { route: { name: 'gigeditoroffers', params: { id: id } }, icon: 'mdi-email-multiple' }
        ])
      } else {
        eventBus.$emit('appTabsSet', [
          { route: { name: 'gigeditordetails', params: { id: id } }, icon: 'mdi-music' },
          { route: { name: 'gigeditorsearch', params: { id: id } }, icon: 'mdi-magnify' },
          { route: { name: 'gigeditorshortlist', params: { id: id } }, icon: 'mdi-bookmark-multiple' },
          { route: { name: 'gigeditoroffers', params: { id: id } }, icon: 'mdi-email-multiple' }
        ])
      }
    }
  },
  // eslint-disable-next-line no-unused-vars
  beforeRouteUpdate(to, from, next) {
    this.setTabs(to.params.id)
    if (to.params.id !== from.params.id) {
      this.$store.dispatch('setGigId', to.params.id)
    }
    next()
  },
  created() {
    eventBus.$on('appButtonClick', this.handleAppButtonClick)
    eventBus.$on('cardButtonClick', this.handleCardButtonClick)
    eventBus.$emit('appAlterAppBar', {
      alter: true,
      icon: 'mdi-arrow-left'
    })
    this.setTabs(this.$route.params.id)

    if (this.$route.params.id != 'add') {
      this.$store.dispatch('setGigId', this.$route.params.id)
    } else {
      this.$store.dispatch('setGigId', null)
      eventBus.$emit('appAlterAppBar', { title: 'New Gig' })
    }
  },
  beforeDestroy() {
    this.$store.dispatch('setGigId', null)
    eventBus.$off('appButtonClick', this.handleAppButtonClick)
    eventBus.$off('cardButtonClick', this.handleCardButtonClick)
  }
}
</script>