<template>
  <div>
    <tab-title>Photos</tab-title>
    <tab-content>
      <v-row>
        <v-col cols="12" md="8" offset-md="2">
          <action-card class="mb-4">Upload up to four photos to complement your profile.</action-card>
          <v-form :disabled="!emailVerified || !profileValid">
            <!-- TODO: Elements for website address -->
            <profile-editor-photo mode="feature" :index="featureIndex"></profile-editor-photo>
          </v-form>
          <v-carousel v-model="featureIndex" :key="refreshCarouselKey" height="auto">
            <v-carousel-item v-for="(feature, n) in profileFeaturePhotos" :key="n"
              :src="$store.getters.photoUrl($store.state.user.uid, feature)">
            </v-carousel-item>
          </v-carousel>
        </v-col>
      </v-row>
    </tab-content>
  </div>
</template>

<script>
import TabTitle from '@/components/TabTitle.vue'
import TabContent from '@/components/TabContent.vue'
import ActionCard from '@/components/ActionCard.vue'
import ProfileEditorPhoto from '@/components/ProfileEditorPhoto.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'ProfilePhotosTab',
  components: { TabTitle, TabContent, ActionCard, ProfileEditorPhoto },
  data() {
    return {
      featureIndex: 0,
      refreshCarouselKey: 1
    }
  },
  computed: {
    ...mapGetters([
      'emailVerified',
      'profileFeaturePhotos',
      'profileValid'
    ])
  },
  watch: {
    profileFeaturePhotos() {
      this.refreshCarouselKey += 1
    }
  },
}
</script>