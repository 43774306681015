<template>
  <div>
    <v-text-field
      v-if="showPaypal"
      v-model.trim="promoCode"
      :disabled="promoFrozen"
      label="Promo Code" dense outlined
    >
      <template v-slot:append-outer>
        <v-btn
          @click="getPlans"
          :disabled="!promoCodeValid || promoFrozen"
          color="primary"
        >
          Apply
        </v-btn>
      </template>
    </v-text-field>

    <paypal v-if="showPaypal" :plans="plans"></paypal>
  </div>
</template>

<script>
import { eventBus } from '@/main'
import Paypal from '@/components/Paypal.vue'
import { fbfuncs } from '@/plugins/firebase'
import { httpsCallable } from 'firebase/functions'

export default {
  components: { Paypal },
  data() {
    return {
      promoCode: '',
      promoFrozen: false,
      plans: []
    }
  },
  computed: {
    promoCodeValid() {
      try {
        return this.promoCode.length >= 3 && this.promoCode.length <= 20
      } catch (e) {
        return false
      }
    },
    showPaypal() {
      return this.plans.length
    }
  },
  methods: {
    async getPlans() {
      eventBus.$emit('appAlterAppBar', { loading: true })
      try {
        const getMyPlans = httpsCallable(fbfuncs, 'getMyPlans')
        const result = await getMyPlans({ promo: this.promoCode.toUpperCase() })

        this.plans = result.data.plans ? result.data.plans : []
        if (result.data.error) {
          eventBus.$emit('appWarningSet', { message: result.data.error })
        }
        if (result.data.promo) {
          this.$store.commit('setPromoApplied', result.data.promo)
          this.promoCode = result.data.promo
          this.promoFrozen = true
        }
      } catch (e) {
        eventBus.$emit('appWarningSet', { message: 'Subscription: ' + e })
      }
      eventBus.$emit('appAlterAppBar', { loading: false })
    }
  },
  async created() {
    await this.getPlans()
  }
}
</script>
