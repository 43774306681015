<template>
  <div>
    <v-row class="mb-5">
      <v-col cols="12" sm="8" offset-sm="2">
        <action-card
          :type="tier.card"
          :title="tier.name"
          large
        >
          {{ tier.info }}
        </action-card>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" sm="8" offset-sm="2">
        <paypal-button v-if="showPaypal"></paypal-button>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" sm="8" offset-sm="2">
        <div v-if="filteredSubscriptions.length > 0">
          <p class="text-h6 mt-4">Previous subscriptions</p>
          <p class="text-caption">You can manage your active subscription at your PayPal dashboard.</p>
          <v-simple-table v-for="(s, n) in filteredSubscriptions" :key="n" dense class="ma-4">
            <tbody>
              <tr><th>Plan:</th><td>{{ s.name }}</td></tr>
              <tr><th>Status:</th><td class="text-uppercase">{{ s.status }}</td></tr>
              <tr v-if="s.days >= 0"><th>Expires:</th><td>{{ formattedDate(s.expires) }} (in {{ s.days }} days)</td></tr>
              <tr v-else><th>Expired:</th><td>{{ formattedDate(s.expires) }}</td></tr>
              <tr><th>ID:</th><td>{{ s.id }}</td></tr>
            </tbody>
          </v-simple-table>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { eventBus } from '@/main'
import ActionCard from '@/components/ActionCard.vue'
import PaypalButton from '@/components/PaypalButton.vue'
import { fbfs, fbfuncs } from '@/plugins/firebase'
import { collection, onSnapshot, query, where } from 'firebase/firestore'
import { httpsCallable } from 'firebase/functions'
import { mapGetters } from 'vuex'


export default {
  name: 'SubscriptionView',
  components: { ActionCard, PaypalButton },
  data() {
    return {
      subscriptions: [],
      active: false,
      valid: false,
      dbUnsubscribeChanges: null
    }
  },
  computed: {
    filteredSubscriptions() {
      return this.subscriptions.filter(s => {
        return s.id.startsWith('I-') || s.id.startsWith('C-')
      })
    },
    showPaypal() {
      return !this.accountSubscribed && !!this.profileSkillCount && !!this.areaCount
    },
    tier() {
      if (!this.profileValid) {
        return {
          name: 'PROFILE INCOMPLETE',
          info: 'To use this system you have to add a valid email address, your name, a short introduction and a photo to your profile. You can do this using the Getting Started card on your Dashboard.',
          card: 'warning'
        }
      } else if (this.accountSubscribed) {
        if (!this.profileSkillCount && !this.areaCount) {
          return {
            name: 'FORTE',
            info: 'You have subscribed but no-one can find you because you haven\'t added any skills or areas to your profile.',
            card: 'warning'
          }
        } else if (!this.profileSkillCount) {
          return {
            name: 'FORTE',
            info: 'You have subscribed but no-one can find you because you haven\'t added any skills to your profile.',
            card: 'warning'
          }
        } else if (!this.areaCount) {
          return {
            name: 'FORTE',
            info: 'You have subscribed but no-one can find you because you haven\'t added any areas to your profile.',
            card: 'warning'
          }
        } else {
          return {
            name: 'FORTE',
            info: 'You have subscribed and are listed in the directory!',
            card: 'note'
          }
        }
      } else {
        if (!!this.profileSkillCount && !!this.areaCount) {
          return {
            name: 'PIANO',
            info: 'You are listed in the directory but you have not yet subscribed. If you\'d like to respond to offers please upgrade to FORTE below.',
            card: 'note'
          }
        } else if (this.profileSkillCount) {
          return {
            name: 'FIXER',
            info: 'You are not listed in the directory. If you just want to be a fixer, please delete your skills. If you would like to be listed as a musician, please add areas to your profile.',
            card: 'warning'
          }
        } else if (this.areaCount) {
          return {
            name: 'FIXER',
            info: 'You are not listed in the directory. If you just want to be a fixer, please delete your areas. If you would like to be listed as a musician, please add skills to your profile.',
            card: 'warning'
          }
        } else {
          return {
            name: 'FIXER',
            info: 'You are not listed in the directory. You\'re all set up for fixing.',
            card: 'note'
          }
        }
      }
    },
    ...mapGetters([
      'areaCount',
      'profileValid',
      'profileSkillCount',
      'accountSubscribed'
    ])
  },
  methods: {
    formattedDate(date) {
      return date ? Intl.DateTimeFormat('en-gb', {
        day: 'numeric', month: 'long', year: 'numeric'
      }).format(new Date(date)) : ''
    }
  },
  created() {
    const q = query(collection(fbfs, 'subscriptions'), where('user', '==', this.$store.state.user.uid))
    // eslint-disable-next-line no-unused-vars
    this.dbUnsubscribeChanges = onSnapshot(q, async (snapshot) => {
      try {
        eventBus.$emit('appAlterAppBar', { loading: true })

        const getMySubscriptions = httpsCallable(fbfuncs, 'getMySubscriptions')
        const result = await getMySubscriptions()

        eventBus.$emit('appAlterAppBar', { loading: false })
        this.active = result.data.active
        this.valid = result.data.valid
        this.subscriptions = result.data.subscriptions ? result.data.subscriptions : []
      } catch (e) {
        eventBus.$emit('appAlterAppBar', { loading: false })
        eventBus.$emit('appWarningSet', { message: 'Subscription: ' + e })
      }
    })
  },
  beforeDestroy() {
    if (this.dbUnsubscribeChanges) {
      this.dbUnsubscribeChanges()
    }
  }
}
</script>

<style scoped>
.theme--light.v-data-table > .v-data-table__wrapper > table > tbody > tr:not(:last-child) > td:not(.v-data-table__mobile-row),
.theme--light.v-data-table > .v-data-table__wrapper > table > tbody > tr:not(:last-child) > th:not(.v-data-table__mobile-row) {
  border-bottom: none;
}
</style>