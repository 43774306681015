<template>
  <div>
    <v-app-bar app elevation="0" extension-height="48" color="primarydark onprimary--text">
      <v-app-bar-nav-icon @click="onNavIconClick">
        <v-icon color="onprimary">{{ navIcon }}</v-icon>
      </v-app-bar-nav-icon>

      <v-toolbar-title>{{ title }}</v-toolbar-title>
      <v-spacer></v-spacer>

      <v-fab-transition v-for="i in [ 2, 1, 0 ]" :key="i">
        <v-btn icon
          v-if="buttons[i].show"
          @click="emitButtonClick(i)"
          :disabled="buttons[i].disabled"
          :loading="buttons[i].loading"
          color="secondary">
          <v-icon color="secondary">{{ buttons[i].icon }}</v-icon>
        </v-btn>
      </v-fab-transition>

      <template v-if="tabs.length" v-slot:extension>
        <v-tabs v-model="tabName" grow slider-color="secondary" slider-size="5">
          <v-tab v-for="(t, i) in tabs" :key="i" :to="t.route" exact class="primary">
            <v-icon size="20" color="onprimary">{{ t.icon }}</v-icon>
          </v-tab>
        </v-tabs>
      </template>
    </v-app-bar>

    <v-navigation-drawer app v-model="drawer" color="primarydark">

      <v-list-item>
        <v-spacer></v-spacer>
        <v-icon right @click="drawer=!drawer" color="onprimary">mdi-arrow-left</v-icon>
      </v-list-item>

      <v-list>
        <v-list-item v-for="item in items" :key="item.name" :to="{ name: item.name }">
          <v-list-item-icon>
            <v-icon color="onprimary">{{ item.icon }}</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title class="onprimary--text">{{ item.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <!-- Sign Out  -->
        <v-list-item to="/" @click="doSignOut()">
          <v-list-item-icon>
            <v-icon color="onprimary">mdi-logout</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title class="onprimary--text">Sign Out</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

      </v-list>
    </v-navigation-drawer>

    <v-main>
      <v-progress-linear :active="loading" :indeterminate="loading" color="secondary" height="6">
      </v-progress-linear>
      <v-container fluid class="pa-0">
        <router-view></router-view>
      </v-container>
    </v-main>

    <v-snackbar v-model="warning.show" :color="warning.color" :timeout="6000" centered>
      {{ warning.message }}
      <template v-slot:action="{ attrs }">
        <v-btn text v-bind="attrs" @click="warning.show = false">Close</v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import { eventBus } from '@/main'
import { signOut } from 'firebase/auth'
import { fbauth } from '@/plugins/firebase'

export default {
  data() {
    return {
      alter: false,
      alterIcon: null,
      drawer: false,
      title: '',
      items: [
        { icon: 'mdi-view-dashboard', title: 'Dashboard', name: 'dashboard' },
        { icon: 'mdi-account', title: 'Profile', name: 'profileinfo' },
        { icon: 'mdi-email', title: 'Offers', name: 'offers' },
        { icon: 'mdi-music', title: 'Fixing', name: 'gigsactive' },
        { icon: 'mdi-cog', title: 'Settings', name: 'setappconfig' }
      ],
      buttons: [
        { show: false, icon: null, disabled: false, loading: false },
        { show: false, icon: null, disabled: false, loading: false },
        { show: false, icon: null, disabled: false, loading: false }
      ],
      tabName: null,
      tabs: [],
      loading: false,
      warning: {
        show: false,
        color: 'error',
        message: ''
      }
    }
  },
  computed: {
    navIcon() {
      return this.alter ? this.alterIcon : 'mdi-menu'
    }
  },
  methods: {
    onNavIconClick() {
      if (this.alter) {
        this.emitButtonClick(9)
      } else {
        this.drawer = !this.drawer
      }
    },
    doSignOut() {
      signOut(fbauth).then(() => {
        this.$router.push({ name: 'entrance' })
      }).catch((error) => {
        console.log('SIGN OUT FAILED ' + error)
      })
    },
    emitButtonClick(button) {
      eventBus.$emit('appButtonClick', button)
    }
  },
  created() {
    if (!this.$vuetify.breakpoint.mobile) {
      this.drawer = true
    }
    eventBus.$on('appButtonSet', (button, action) => {
      let b = this.buttons[button]
      if (b) {
        if (action === 'disable') {
          b.disabled = true
        } else if (action === 'enable') {
          b.disabled = false
        } else if (action === 'pending') {
          b.loading = true
        } else if (action === 'finished') {
          b.loading = false
        }
      }
    })
    eventBus.$on('appTabsSet', (tabs) => {
      if (tabs === null) {
        this.tabs = []
        this.tabName = null
      } else {
        this.tabs = tabs
      }
    })
    eventBus.$on('appAlterAppBar', (param) => {
      if (Object.hasOwn(param, 'alter')) {
        this.alter = param.alter
      }
      if (Object.hasOwn(param, 'icon')) {
        this.alterIcon = param.icon
      }
      if (Object.hasOwn(param, 'title')) {
        this.title = param.title
      }
      if (Object.hasOwn(param, 'buttons')) {
        this.buttons = param.buttons
      }
      if (Object.hasOwn(param, 'loading')) {
        this.loading = param.loading
      }
    })
    eventBus.$on('appWarningSet', (param) => {
      if (Object.hasOwn(param, 'color')) {
        this.warning.color = param.color
      } else {
        this.warning.color = 'error'
      }
      this.warning.message = param.message
      this.warning.show = true
    })
  }
}
</script>

<style scoped>
/* Style tabs */
a.v-tab {
  min-width: 48px;
  padding: 0 8px;
}
</style>