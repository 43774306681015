<template>
  <div>
    <skill-filter v-if="showSearch"
      @skillSelect="selectItem"
      :disabled="disabled"
      :label="label" icon
    ></skill-filter>
    <div class="pt-4">
      <v-chip v-for="skill in selectedSkills" :key="skill.code"
        @click="topItem(skill.code)"
        close @click:close="removeItem(skill.code)"
        color="primarylight onprimarylight--text"
        class="ma-2"
      >
        {{ skill.name }}
      </v-chip>
    </div>
  </div>
</template>

<script>
import SkillFilter from '@/components/SkillFilter.vue'
import { eventBus } from '@/main'

export default {
  components: { SkillFilter },
  props: {
    label: {
      type: String,
      default: 'Search'
    },
    initialCodes: {
      type: Array,
      required: true
    },
    maxCount: {
      type: Number,
      default: 10
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      selectedCodes: this.initialCodes,
      showSearch: false,
    }
  },
  computed: {
    selectedCount() {
      return this.selectedCodes.length
    },
    selectedSkills() {
      return this.selectedCodes.map((code) => ({
        'code': code,
        'name': this.$store.getters.skillName(code)
      }))
    }
  },
  watch: {
    selectedCount() {
      if (this.selectedCount < this.maxCount) {
        this.showSearch = true
      } else {
        this.showSearch = false
      }
    }
  },
  methods: {
    selectItem(code) {
      let i = this.selectedCodes.indexOf(code)
      if (i < 0 && this.selectedCount < this.maxCount) {
        this.selectedCodes.push(code)
        this.$emit('skillSetSelect', this.selectedCodes)
      }
    },
    removeItem(code) {
      let i = this.selectedCodes.indexOf(code)
      if (i >= 0) {
        this.selectedCodes.splice(i, 1)
        this.$emit('skillSetSelect', this.selectedCodes)
      }
    },
    topItem(code) {
      let i = this.selectedCodes.indexOf(code)
      if (i >= 0) {
        this.selectedCodes.splice(i, 1)
        this.selectedCodes.unshift(code)
        this.$emit('skillSetSelect', this.selectedCodes)
      }
    },
    resetForms() {
    }
  },
  created() {
    if (this.selectedCount < this.maxCount) {
      this.showSearch = true
    }
    eventBus.$on('panelReset', this.resetForms)
  },
  beforeDestroy() {
    eventBus.$off('panelReset', this.resetForms)
  }
}
</script>

<style>

</style>