<template>
  <v-stepper v-model="step" vertical flat :key="refreshStepperKey">
    <!-- Start -->
    <div class="px-6 py-4">
      <div>
        Follow these steps to create or update your profile.
      </div>
      <v-btn @click="step=1" :disabled="step>0" :color="buttonColor" class="mr-2 mb-1">
        Start
      </v-btn>
    </div>

    <!-- Email -->
    <v-stepper-step :complete="emailVerified" step="1">
      Verify your email
    </v-stepper-step>
    <v-stepper-content step="1">
      <div class="stepper-content-text">
        You must have a valid email address to use our service. Enter it below, then we will send you an email with a link to verify that address. Click the link in the email and come back here to continue. Remember to check your spam folder if you can't find our email in your inbox.
      </div>
      <email-field></email-field>
      <v-btn @click="step+=1" :disabled="!emailVerified" :color="buttonColor" class="mr-2 mb-1">
        Next
      </v-btn>
    </v-stepper-content>

    <!-- Name -->
    <v-stepper-step :complete="profileNameValid" step="2">
      Add your name
    </v-stepper-step>
    <v-stepper-content step="2">
      <div class="stepper-content-text">
        Enter your name here.
      </div>
      <profile-editor-name
        savelabel="Next" @saveclick="onProfileNameNext()" :savecolor="buttonColor"
        cancellabel="Back" @cancelclick="step-=1"
        :key="refreshStep2Key"
      ></profile-editor-name>
    </v-stepper-content>

    <!-- Introduction -->
    <v-stepper-step :complete="profileIntroValid" step="3">
      Introduce yourself
    </v-stepper-step>
    <v-stepper-content step="3">
      <div class="stepper-content-text">
        Introduce yourself in a few words.
        This will be your first impression to the fixers when your profile shows up in their search results.
      </div>
      <profile-editor-intro
        savelabel="Next" @saveclick="onProfileIntroNext()" :savecolor="buttonColor"
        cancellabel="Back" @cancelclick="step-=1"
        :key="refreshStep3Key"
      ></profile-editor-intro>
    </v-stepper-content>

    <!-- Avatar -->
    <v-stepper-step :complete="profileAvatarValid" step="4">
      Upload a profile photo
    </v-stepper-step>
    <v-stepper-content step="4">
      <div class="stepper-content-text">
        Choose one that has your face in the middle.
        This will be shown on your profile card when it shows up in the fixers' search results.
      </div>
      <v-avatar size="120">
        <img :src="$store.getters.photoUrl($store.state.user.uid, profileAvatar)" alt="Profile photo" />
      </v-avatar>
      <profile-editor-photo mode="avatar" :key="refreshStep4Key"></profile-editor-photo>
      <v-btn @click="step+=1" :disabled="!profileAvatarValid" :color="buttonColor" class="mr-2 mb-1">
        Next
      </v-btn>
      <v-btn text @click="step-=1" class="mr-2 mb-1">
        Back
      </v-btn>
    </v-stepper-content>

    <!-- Select role -->
    <template v-if="role!=='muso'">
      <v-stepper-step :complete="!!roleChosen" step="5">
        Select role
      </v-stepper-step>
      <v-stepper-content step="5">
        <div class="stepper-content-text">
          Choose Muso to list yourself in the directory and be searchable by fixers.
          Choose Fixer to fix only.
          Anyone listed in the directory can also search for musos for gigs or as a dep.
        </div>
        <v-btn @click="roleChosen='muso'" :color="buttonColor" class="mr-2 mb-1">
          Muso
        </v-btn>
        <v-btn @click="roleChosen='fixer';step+=1" :color="buttonColor" class="mr-2 mb-1">
          Fixer
        </v-btn>
        <v-btn text @click="step-=1" class="mr-2 mb-1">
          Back
        </v-btn>
      </v-stepper-content>
    </template>

    <!-- Skills -->
    <template v-if="role==='muso'">
      <v-stepper-step :complete="!!profileSkillCount" step="5">
        Add your skills
      </v-stepper-step>
      <v-stepper-content step="5">
        <div class="stepper-content-text">
          Start typing in the search box then select your skill from the list.
          If you can't find some of your skills, try searching for genre or style.
          Move your primary skills to the front by tapping them.
        </div>
        <skill-selector
          :initial-codes="profileSkills"
          @skillSetSelect="dbSaveSkills"
          :disabled="!emailVerified || !profileValid"
          label="Add new skill"
        ></skill-selector>
        <v-btn @click="step+=1" :disabled="!profileSkillCount" :color="buttonColor" class="mr-2 mb-1">
          Next
        </v-btn>
        <v-btn text @click="step-=1" class="mr-2 mb-1">
          Back
        </v-btn>
      </v-stepper-content>

      <!-- Areas -->
      <v-stepper-step :complete="!!areaCount" step="6">
        Add your areas
      </v-stepper-step>
      <v-stepper-content step="6">
        <div class="stepper-content-text">
          Select the locations where you would like to work.
          Type a name or postcode, press the search button and choose your place from the pop-up list.
          Adjust the size of the area by pressing the pin icon and moving the slider.
        </div>
        <area-editor mapoff :disabled="!emailVerified || !profileValid" :maxCount="maxAreaCount"></area-editor>
        <v-btn @click="step+=1" :disabled="!areaCount" :color="buttonColor" class="mr-2 mb-1">
          Next
        </v-btn>
        <v-btn text @click="step-=1" class="mr-2 mb-1">
          Back
        </v-btn>
      </v-stepper-content>

      <!-- Muso finished -->
      <v-stepper-step :complete="musoFinished" step="7">
        Congratulations!
      </v-stepper-step>
      <v-stepper-content step="7">
        <div class="stepper-content-text">
          You are now listed in our directory with PIANO membership.
          Fixers can find you in their searches and send you gig offers.
          <p></p>
          To be able to reply to gig offers, upgrade to FORTE membership.
          <p></p>
          We recommend adding a biog and publicity photos to your profile.
          You can also review your selected areas on your map.
        </div>
        <v-btn @click="$router.push({name:'profilebiog'})" :color="buttonColor" class="mr-2 mb-1">
          Profile
        </v-btn>
        <v-btn @click="$router.push({name:'setsubscriptionconfig'})" :color="buttonColor" class="mr-2 mb-1">
          Upgrade
        </v-btn>
      </v-stepper-content>
    </template>

    <template v-if="role==='fixer'">
      <!-- Fixer finished -->
      <v-stepper-step :complete="fixerFinished" step="6">
        Congratulations!
      </v-stepper-step>
      <v-stepper-content step="6">
        <div class="stepper-content-text">
          You are ready to find musos.
          You can modify your profile details at the Profile Editor.
        </div>
        <v-btn @click="$router.push({name:'profileinfo'})" :color="buttonColor" class="mr-2 mb-1">
          Update profile
        </v-btn>
        <v-btn @click="$router.push({name:'gigsactive'})" :color="buttonColor" class="mr-2 mb-1">
          Start fixing
        </v-btn>
      </v-stepper-content>
    </template>

  </v-stepper>
</template>

<script>
import { eventBus } from '@/main'
import EmailField from '@/components/EmailField.vue'
import ProfileEditorName from '@/components/ProfileEditorName.vue'
import ProfileEditorIntro from '@/components/ProfileEditorIntro.vue'
import ProfileEditorPhoto from '@/components/ProfileEditorPhoto.vue'
import SkillSelector from '@/components/SkillSelector.vue'
import AreaEditor from '@/components/AreaEditor.vue'
import { fbfs } from '@/plugins/firebase'
import { doc, setDoc } from "firebase/firestore"
import { mapGetters } from 'vuex'

export default {
  components: { EmailField, ProfileEditorName, ProfileEditorIntro, ProfileEditorPhoto, SkillSelector, AreaEditor },
  data() {
    return {
      step: -1,
      roleChosen: null,
      refreshStepperKey: 1,
      refreshStep2Key: 1,
      refreshStep3Key: 1,
      refreshStep4Key: 1
    }
  },
  computed: {
    role() {
      if (this.roleChosen === 'muso' || !!this.profileSkillCount || !!this.areaCount || this.accountSubscribed) {
        return 'muso'
      } else if (this.roleChosen === 'fixer') {
        return 'fixer'
      } else {
        return null
      }
    },
    musoFinished() {
      return this.emailVerified && this.profileValid && !!this.profileSkillCount && !!this.areaCount && this.accountSubscribed && this.step > 7
    },
    fixerFinished() {
      return this.emailVerified && this.profileValid && this.roleChosen === 'fixer'
    },
    ...mapGetters([
      'emailVerified',
      'profileNameValid',
      'profileIntroValid',
      'profileAvatar',
      'profileAvatarValid',
      'profileValid',
      'profileSkills',
      'profileSkillCount',
      'accountSubscribed',
      'maxAreaCount',
      'areaCount'
    ]),
    buttonColor() {
      return 'primarylight onprimarylight--text'
    }
  },
  watch: {
    role() {
      this.refreshStepperKey += 1
    },
    step() {
      if (this.step === 2) {
        this.refreshStep2Key += 1
      } else if (this.step === 3) {
        this.refreshStep3Key += 1
      } else if (this.step === 4) {
        this.refreshStep4Key += 1
      } else if (this.step === 6 && this.role === 'fixer') {
        this.$emit('finished')
      } else if (this.step === 7 && this.role === 'muso') {
        this.$emit('finished')
      }
    }
  },
  methods: {
    async dbSaveSkills(skills) {
      eventBus.$emit('appAlterAppBar', { loading: true })
      try {
        await setDoc(
          doc(fbfs, 'profiles', this.$store.state.user.uid),
          { skills: skills },
          { merge: true }
        )
      } catch (e) {
        eventBus.$emit('appWarningSet', { message: 'Saving skills failed: ' + e })
      }
      eventBus.$emit('appAlterAppBar', { loading: false })
    },
    onProfileNameNext() {
      if (this.profileNameValid) {
        this.step += 1
      }
    },
    onProfileIntroNext() {
      if (this.profileIntroValid) {
        this.step += 1
      }
    }
  },
  async created() {
  }
}
</script>

<style scoped>
.v-sheet.v-stepper {
  background-color: #F5F5F5;
}
.v-stepper__step {
    padding-bottom: 8px;
}
.stepper-content-text {
  margin-bottom: 16px;
}
.v-btn {
  margin-top: 12px;
}
</style>