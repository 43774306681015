<template>
  <div>
    <tab-title>Info</tab-title>
    <tab-content>
      <v-form :disabled="!emailVerified">
        <v-row>
          <v-col cols="12" sm="8" offset-sm="2">
            <action-card class="mb-4">To create your profile add a name, a short sentence about yourself and upload a profile photo.</action-card>
            <profile-editor-name></profile-editor-name>
            <profile-editor-intro></profile-editor-intro>
          </v-col>
          <v-col cols="12" sm="8" offset-sm="2" class="text-center">
            <v-avatar size="120">
              <img :src="$store.getters.photoUrl($store.state.user.uid, profileAvatar)" alt="Profile photo" />
            </v-avatar>
            <profile-editor-photo mode="avatar"></profile-editor-photo>
          </v-col>
        </v-row>
      </v-form>
    </tab-content>
  </div>
</template>

<script>
import TabTitle from '@/components/TabTitle.vue'
import TabContent from '@/components/TabContent.vue'
import ActionCard from '@/components/ActionCard.vue'
import ProfileEditorName from '@/components/ProfileEditorName.vue'
import ProfileEditorIntro from '@/components/ProfileEditorIntro.vue'
import ProfileEditorPhoto from '@/components/ProfileEditorPhoto.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'ProfileInfoTab',
  components: { TabTitle, TabContent, ActionCard, ProfileEditorName, ProfileEditorIntro, ProfileEditorPhoto },
  computed: {
    ...mapGetters([
      'emailVerified',
      'profileAvatar'
    ])
  }
}
</script>