<template>
  <div>
    <tab-title>Sender</tab-title>
    <tab-content>
      <profile-viewer v-if="!offerBarred" :id="offer.from.id"></profile-viewer>
    </tab-content>
  </div>
</template>

<script>
import ProfileViewer from '@/components/ProfileViewer.vue'
import TabTitle from '@/components/TabTitle.vue'
import TabContent from '@/components/TabContent.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'OfferSenderTab',
  components: { ProfileViewer, TabTitle, TabContent },
  computed: {
    ...mapGetters([
      'offer',
      'offerBarred'
    ])
  }
}
</script>
