<template>
  <div>
    <div>
      <v-radio-group v-model="planId" mandatory>
        <v-radio v-for="p in plans" :key="p.id" :label="p.name" :value="p.id" color="#f2ba36"></v-radio>
      </v-radio-group>
      <div id="paypal-button-container"></div>
    </div>
  </div>
</template>

<!-- Used example at
     https://github.com/paypal-examples/vue-integration
-->

<script>
import { eventBus } from '@/main'
import { fbfs } from '@/plugins/firebase'
import { doc, setDoc, serverTimestamp } from 'firebase/firestore'
import { loadScript } from '@paypal/paypal-js'

export default {
  name: 'PayPal-Checkout',
  props: {
    plans: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      planId: null,
    }
  },
  methods: {
    createSubscription(data, actions) {
      return actions.subscription.create({ 'plan_id': this.planId })
    },
    // eslint-disable-next-line no-unused-vars
    async onApprove(data, actions) {
      const subsData = {
        events: { init: { id: 'FE', timestamp: serverTimestamp() }},
        user: this.$store.state.user.uid,
        order: data.orderID,
        source: data.paymentSource,
        timestamp: serverTimestamp()
      }
      if (this.$store.state.subs.promoApplied) {
        subsData['promo'] = this.$store.state.subs.promoApplied
      }
      try {
        await setDoc(
          doc(fbfs, 'subscriptions', data.subscriptionID),
          subsData,
          { merge: true }
        )
        eventBus.$emit('appWarningSet', { color: 'success', message: 'Payment approved' })
      } catch (e) {
        eventBus.$emit('appWarningSet', { message: 'Save failed: ' + e })
      }
    },
    async onCancel(data) {
      const subsData = {
        events: { cancelled: { id: 'FE', timestamp: serverTimestamp() }},
        user: this.$store.state.user.uid,
        order: data.orderID,
        source: 'cancelled',
        timestamp: serverTimestamp()
      }
      if (this.$store.state.subs.promoApplied) {
        subsData['promo'] = this.$store.state.subs.promoApplied
      }
      try {
        await setDoc(
          doc(fbfs, 'subscriptions', data.orderID),
          subsData,
          { merge: true }
        )
      } catch (e) {
        eventBus.$emit('appWarningSet', { message: 'Save failed: ' + e })
      }
    },
    onError(err) {
      eventBus.$emit('appWarningSet', { message: 'Payment error: ' + err })
    }
  },
  beforeCreate() {
    eventBus.$emit('appAlterAppBar', { loading: true })
    loadScript({
      'client-id': CLIENT_ID, 'vault': true, 'intent': 'subscription'
    }).then((paypal) => {
      eventBus.$emit('appAlterAppBar', { loading: false })
      paypal.Buttons({
        style: {
          shape: 'rect', color: 'gold', layout: 'vertical', label: 'subscribe'
        },
        createSubscription: this.createSubscription,
        onApprove: this.onApprove,
        onCancel: this.onCancel,
        onError: this.onError
      }).render('#paypal-button-container')
    }).catch((e) => {
      eventBus.$emit('appAlterAppBar', { loading: false })
      eventBus.$emit('appWarningSet', { message: 'PayPal error: ' + e })
    })
  },
  created() {
  }
}
const CLIENT_ID = 'AaGSOqWEuDrKXxP5YyDjl_GpVcDuzwXnenXyo6nWoYDvg8xThO852jOEcU8Uu59lRQq0cU7aWjvChfpB'
</script>

<style>
#paypal-button-container {
  position: relative;
  z-index: 0 !important;
}
</style>
