import { render, staticRenderFns } from "./DashboardView.vue?vue&type=template&id=41f088aa&scoped=true&"
import script from "./DashboardView.vue?vue&type=script&lang=js&"
export * from "./DashboardView.vue?vue&type=script&lang=js&"
import style0 from "./DashboardView.vue?vue&type=style&index=0&id=41f088aa&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "41f088aa",
  null
  
)

export default component.exports