<template>
  <div>
    <tab-title>Shortlist {{ tabTitleNote }}</tab-title>
    <tab-content>
      <v-row>
        <v-col cols="12" sm="8" offset-sm="2">
          <action-card class="mb-4">Select a card to learn more about the performer. Use the envelope to send an offer. Deselect the bookmark to remove them from the shortlist.</action-card>
          <profile-card v-for="p in profilesSelected" :key="p.id"
            :mode="gigArchived?'archived':'selected'" :profile="p"
            class="my-3">
          </profile-card>
        </v-col>
      </v-row>
    </tab-content>
  </div>
</template>

<script>
import TabTitle from '@/components/TabTitle.vue'
import TabContent from '@/components/TabContent.vue'
import ActionCard from '@/components/ActionCard.vue'
import ProfileCard from '@/components/ProfileCard.vue'
import { eventBus } from '@/main'
import { mapGetters } from 'vuex'

export default {
  name: 'GigEditorShortlistTab',
  components: { TabTitle, TabContent, ActionCard, ProfileCard },
  data() {
    return {
    }
  },
  computed: {
    tabTitleNote() {
      return this.gigArchived ? '(ARCHIVED)' : ''
    },
    profilesSelected() {
      return Object.keys(this.gigProfiles)
        .filter(pk => this.gigProfiles[pk].selected)
        .map(pk => this.gigProfiles[pk])
    },
    ...mapGetters([
      'gigProfiles',
      'gigArchived'
    ])
  },
  created() {
    eventBus.$emit('appAlterAppBar', {
      buttons: [ { show: false }, { show: false }, { show: false } ]
    })
  }
}
</script>