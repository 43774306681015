<template>
  <div>
    <tab-title>Skills</tab-title>
    <tab-content>
      <v-row>
        <v-col cols="12" sm="8" offset-sm="2">
          <action-card class="mb-4">For performers: Select your skills here. Move your primary skills to the front by tapping them. The fixer will use these in their searches to find you.</action-card>
          <action-card v-if="showSubscribeWarning" type="info" to-route="setsubscriptionconfig" class="mb-4">
            Your skills and areas are listed in the directory.
            If you'd like to respond to offers, upgrade to FORTE by clicking here.
          </action-card>
          <action-card v-if="showListingWarning" type="warning" class="mb-4">
            You need to add both your skills and areas to be listed in the directory.
          </action-card>
          <skill-selector
            :initial-codes="profileSkills"
            @skillSetSelect="dbSaveSkills"
            :disabled="!emailVerified || !profileValid"
            label="Add new skill"
          ></skill-selector>
        </v-col>
      </v-row>
    </tab-content>
  </div>
</template>

<script>
import { eventBus } from '@/main'
import TabTitle from '@/components/TabTitle.vue'
import TabContent from '@/components/TabContent.vue'
import ActionCard from '@/components/ActionCard.vue'
import SkillSelector from '@/components/SkillSelector.vue'
import { fbfs } from '@/plugins/firebase'
import { doc, setDoc } from "firebase/firestore"
import { mapGetters } from 'vuex'

export default {
  name: 'ProfileSkillsTab',
  components: { TabTitle, TabContent, ActionCard, SkillSelector },
  computed: {
    showSubscribeWarning() {
      return (!!this.profileSkillCount && !!this.areaCount)
        && !this.accountSubscribed
    },
    showListingWarning() {
      return (!!this.profileSkillCount && !this.areaCount)
        || (!this.profileSkillCount && !!this.areaCount)
    },
    ...mapGetters([
      'emailVerified',
      'profileValid',
      'profileSkills',
      'profileSkillCount',
      'accountSubscribed',
      'areaCount'
    ])
  },
  methods: {
    async dbSaveSkills(skills) {
      eventBus.$emit('appAlterAppBar', { loading: true })
      try {
        await setDoc(
          doc(fbfs, 'profiles', this.$store.state.user.uid),
          { skills: skills }, { merge: true }
        )
      } catch (e) {
        eventBus.$emit('appWarningSet', { message: 'Saving skills failed: ' + e })
      }
      eventBus.$emit('appAlterAppBar', { loading: false })
    }
  },
}
</script>