<template>
  <div>
    <tab-title>Details</tab-title>
    <tab-content>
      <p class="text-h5">{{ offer.gig.title }}</p>
      <action-card v-if="offer.gig.archived" type="info">This gig has been archived by the sender!</action-card>
      <table class="text-body-1">
        <tr>
          <td><span class="font-weight-bold">Address:</span></td>
          <td>{{ offer.gig.location.address }}</td>
        </tr>
        <tr>
          <td><span class="font-weight-bold">Date:</span></td>
          <td>{{ formatDate(offer.gig.date) }}</td>
        </tr>
        <tr>
          <td><span class="font-weight-bold">Fee:</span></td>
          <td>£ {{ offer.fee }}</td>
        </tr>
        <tr>
          <td><span class="font-weight-bold">Skills:</span></td>
          <td>{{ offer.skills }}</td>
        </tr>
        <tr>
          <td><span class="font-weight-bold">Calls:</span></td>
          <td>{{ offer.multicall ? 'Multiple calls out' : 'Only call out' }}</td>
        </tr>
        <tr>
          <td><span class="font-weight-bold">From:</span></td>
          <td v-if="!offerBarred">{{ offer.from.name }}</td>
          <td v-else></td>
        </tr>
        <tr>
          <td><span class="font-weight-bold">Contact:</span></td>
          <td v-if="!offerBarred">{{ offer.from.phone }}</td>
          <td v-else></td>
        </tr>
        <tr>
          <td colspan="2">
            <action-card v-if="offerBarred" type="warning" to-route="setsubscriptionconfig" class="mb-4">
              If you'd like to reveal the sender of this offer, upgrade to FORTE by clicking here. This offer comes with promo code OFFER50 giving you 50% discount for the first year of your membership.
            </action-card>
            <action-card v-else>Please contact the sender directly on this number to accept or decline this gig.</action-card>
          </td>
        </tr>
      </table>
      <div class="text-body-1 mt-4">
        <span class="font-weight-bold">Note from the sender:</span>
        <p>{{ offer.note }}</p>
        <span class="font-weight-bold">Further details:</span>
        <div v-html="offer.gig.details" class="text-formatted"></div>
      </div>
    </tab-content>
  </div>
</template>

<script>
import TabTitle from '@/components/TabTitle.vue'
import TabContent from '@/components/TabContent.vue'
import ActionCard from '@/components/ActionCard.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'OfferDetailsTab',
  components: { TabTitle, TabContent, ActionCard },
  computed: {
    ...mapGetters([
      'offer',
      'offerBarred'
    ])
  },
  methods: {
    formatDate(date) {
      return Intl.DateTimeFormat('en-gb', {
        weekday: 'long', day: 'numeric', month: 'long', year: 'numeric'
      }).format(new Date(date))
    }
  }
}
</script>

<style scoped>
td {
  padding: 4px;
}
.text-formatted ::v-deep(h1) {
  font-size: 1.5rem;
  font-weight: 400;
  line-height: 2rem;
  letter-spacing: normal;
  font-family: "Roboto", sans-serif;
}
.text-formatted ::v-deep(h2) {
  font-size: 1.25rem;
  font-weight: 500;
  line-height: 2rem;
  letter-spacing: 0.0125em;
  font-family: "Roboto", sans-serif;
}
.text-formatted ::v-deep(p) {
  font-size: 1rem;
  font-weight: normal;
  line-height: 1.75rem;
  letter-spacing: 0.009375em;
  font-family: "Roboto", sans-serif;
}
</style>
