<template>
  <router-view></router-view>
</template>

<script>
import { eventBus } from '@/main'

export default {
  name: 'GigsView',
  methods: {
    handleAppButtonClick(button) {
      if (button === 0) {
        this.$router.push({ name: 'gigeditordetails', params: { id: 'add' } })
      }
    }
  },
  created() {
    eventBus.$on('appButtonClick', this.handleAppButtonClick)
    eventBus.$emit('appTabsSet', [
      { route: '/gigs/active', icon: 'mdi-music' },
      { route: '/gigs/archive', icon: 'mdi-archive' }
    ])
    eventBus.$emit('appAlterAppBar', {
      alter: false,
      title: 'Gig list',
      buttons: [
        { show: true, icon: 'mdi-plus-circle', disabled: false, loading: false },
        { show: false },
        { show: false }
      ]
    })
  },
  beforeDestroy() {
    eventBus.$off('appButtonClick', this.handleAppButtonClick)
  }
}
</script>
