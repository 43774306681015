<template>
  <div>
    <v-text-field v-model.trim="emailInput"
      @change="handleEmailInputChange"
      @click:append-outer="handleClick"
      @keydown.enter="handleClick"
      :loading="loadingColor"
      :rules="emailRules"
      prepend-icon="mdi-at"
      label="Email"
    >
    <template v-slot:append-outer>
      <v-btn v-if="showSendButton"
        @click="handleClick()"
        :disabled="showIcon.disabled"
        :color="showIcon.color"
        icon class="ml-4"
      >
        <v-icon>{{ showIcon.name }}</v-icon>
      </v-btn>
      <v-icon v-else :color="showIcon.color" class="ml-4">
        {{ showIcon.name }}
      </v-icon>
    </template>
    </v-text-field>
    <action-card v-show="showEmailWarning"
      type="warning" noicon
      class="mb-4">
      Provide a valid email address
    </action-card>
    <action-card v-show="showVerifyWarning"
      clickable @click="reloadUserData"
      type="warning"
      icon="mdi-reload-alert"
      large
      class="mb-4">
      A verification email has been sent to the address above.
      Have a look and click the link in the email to verify the new address. 
      When done, click this card to refresh your status.
    </action-card>
  </div>
</template>

<script>
import { eventBus } from '@/main'
import ActionCard from '@/components/ActionCard.vue'
import { fbfs } from '@/plugins/firebase'
import { updateEmail, sendEmailVerification } from 'firebase/auth'
import { doc, updateDoc } from 'firebase/firestore'

export default {
  components: { ActionCard },
  data() {
    return {
      emailInput: null,
      loading: false,
      verify: false,
      emailRules: [
        (v) => (!!v) || 'Email address required',
        (v) => (/.+@.+\..+/.test(v)) || 'Invalid format'
      ]
    }
  },
  computed: {
    verified() {
      try {
        return this.$store.state.user.emailVerified
      } catch (e) {
        return false
      }
    },
    showSendButton() {
      return this.emailInput !== this.$store.state.user.email
    },
    showIcon() {
      if (this.showSendButton) {
        if (this.emailInputValid) {
          return { name: 'mdi-send', color: 'primary', disabled: false }
        } else {
          return { name: 'mdi-send', color: 'primary', disabled: true }
        }
      } else {
        if (this.verified) {
          return { name: '', color: '' }
        } else {
          return { name: 'mdi-alert', color: 'error' }
        }
      }
    },
    emailInputValid() {
      return !!this.emailInput && /.+@.+\..+/.test(this.emailInput)
    },
    showEmailWarning() {
      return !this.emailInputValid
    },
    showVerifyWarning() {
      return this.verify && !this.verified
    },
    loadingColor() {
      return this.loading ? 'secondary' : false
    }
  },
  methods: {
    handleEmailInputChange() {
      if (!this.emailInputValid) {
        this.emailInput = this.$store.state.user.email
      }
    },
    handleClick() {
      if (this.showSendButton && this.emailInputValid) {
        this.updateEmail()
      }
    },
    async updateEmail() {
      if (!this.emailInputValid) return

      this.loading = true
      try {
        await updateEmail(this.$store.state.user, this.emailInput)
        await sendEmailVerification(this.$store.state.user)
        await this.$store.state.user.reload()
        this.verify = true
      } catch (e) {
        const emsg = e.message
        let umsg = null
        if (emsg.includes('auth/requires-recent-login')) {
          umsg = 'Recent login required. Try signing out and signing in again.'
        } else if (emsg.includes('auth/invalid-email')) {
          umsg = 'Invalid email address'
        } else if (emsg.includes('auth/missing-email')) {
          umsg = 'Missing email address'
        } else {
          umsg = emsg
        }
        eventBus.$emit('appWarningSet', { message: 'Update failed: ' + umsg })
      }
      this.emailInput = this.$store.state.user.email
      this.loading = false
    },
    async reloadUserData() {
      this.loading = true
      try {
        await this.$store.state.user.reload()
        this.emailInput = this.$store.state.user.email
        if (this.verified) {
          this.verify = false
          await updateDoc(doc(fbfs, 'accounts', this.$store.state.user.uid),
            { email: this.$store.state.user.email}
          )
        }
      } catch (e) {
        eventBus.$emit('appWarningSet', { message: 'Reload failed: ' + e })
      }
      this.loading = false
    }
  },
  created() {
    if (this.$store.state.user) {
      this.emailInput = this.$store.state.user.email
      this.verify = this.emailInput ? !this.verified : false
    }
  }
}
</script>