<template>
  <div>
    <tab-title>Search {{ tabTitleNote }}</tab-title>
    <tab-content>
      <v-row>
        <v-col cols="12" sm="8" offset-sm="2">
          <skill-filter
            @skillSelect="findProfiles"
            :label="findProfilesLabel"
            :loading="findingProfiles"
            :disabled="searchDisabled"
          ></skill-filter>
        </v-col>
        <v-col cols="12" sm="8" offset-sm="2" :key="refreshProfiles">
          <action-card v-if="!profilesFoundEmpty" class="mb-4">Bookmark the profiles you would like to shortlist for contact.</action-card>
          <action-card v-if="showSorryCard" type="info" class="mb-4">Sorry, we couldn't find anybody in this area yet. Our database is growing fast so please check back soon!</action-card>
          <profile-card v-for="p in profilesFoundWithRange1" :key="p.id"
            :mode="gigArchived?'archived':'search'" :profile="p"
            class="my-3">
          </profile-card>
          <profile-card v-for="p in profilesFoundWithRange2" :key="p.id"
            :mode="gigArchived?'archived':'search'" :profile="p"
            class="my-3">
          </profile-card>
          <profile-card v-for="p in profilesFoundWithRange3" :key="p.id"
            :mode="gigArchived?'archived':'search'" :profile="p"
            class="my-3">
          </profile-card>
          <profile-card v-for="p in profilesFoundWithRange4" :key="p.id"
            :mode="gigArchived?'archived':'search'" :profile="p"
            class="my-3">
          </profile-card>
          <profile-card v-for="p in profilesFoundWithRange5" :key="p.id"
            :mode="gigArchived?'archived':'search'" :profile="p"
            class="my-3">
          </profile-card>
          <div v-if="profilesFoundWithRange6NotEmpty" class="mt-10">
            <span class="text-h6">Extended search results</span><br>
            <span class="text-body-2">these musos are in areas nearby</span>
          </div>
          <profile-card v-for="p in profilesFoundWithRange6" :key="p.id"
            :mode="gigArchived?'archived':'search'" :profile="p"
            class="my-3">
          </profile-card>
        </v-col>
      </v-row>
    </tab-content>
  </div>
</template>

<script>
import TabTitle from '@/components/TabTitle.vue'
import TabContent from '@/components/TabContent.vue'
import ActionCard from '@/components/ActionCard.vue'
import SkillFilter from '@/components/SkillFilter.vue'
import ProfileCard from '@/components/ProfileCard.vue'
import { eventBus } from '@/main'
import { httpsCallable } from 'firebase/functions'
import { fbfuncs } from '@/plugins/firebase'
import { mapGetters } from 'vuex'

export default {
  name: 'GigEditorSearchTab',
  components: { TabTitle, TabContent, ActionCard, SkillFilter, ProfileCard },
  data() {
    return {
      showSorryCard: false,
      findingProfiles: false,
      refreshProfiles: 1
    }
  },
  computed: {
    tabTitleNote() {
      return this.gigArchived ? '(ARCHIVED)' : ''
    },
    searchDisabled() {
      return !this.emailVerified || !this.profileValid || !this.gigId || this.gigArchived
    },
    findProfilesLabel() {
      if (this.gigLocation.name) {
        return 'Search around ' + this.gigLocation.name
      } else {
        return 'Select gig address first'
      }
    },
    profilesFoundEmpty() {
      return Object.keys(this.gigProfilesFound).length === 0
    },
    profilesFoundWithRange1() {
      return Object.keys(this.gigProfilesFound)
        .filter(pk => this.gigProfilesFound[pk].range === 1)
        .map(pk => this.gigProfilesFound[pk])
    },
    profilesFoundWithRange2() {
      return Object.keys(this.gigProfilesFound)
        .filter(pk => this.gigProfilesFound[pk].range === 2)
        .map(pk => this.gigProfilesFound[pk])
    },
    profilesFoundWithRange3() {
      return Object.keys(this.gigProfilesFound)
        .filter(pk => this.gigProfilesFound[pk].range === 3)
        .map(pk => this.gigProfilesFound[pk])
    },
    profilesFoundWithRange4() {
      return Object.keys(this.gigProfilesFound)
        .filter(pk => this.gigProfilesFound[pk].range === 4)
        .map(pk => this.gigProfilesFound[pk])
    },
    profilesFoundWithRange5() {
      return Object.keys(this.gigProfilesFound)
        .filter(pk => this.gigProfilesFound[pk].range === 5)
        .map(pk => this.gigProfilesFound[pk])
    },
    profilesFoundWithRange6() {
      return Object.keys(this.gigProfilesFound)
        .filter(pk => this.gigProfilesFound[pk].range === 6)
        .map(pk => this.gigProfilesFound[pk])
    },
    profilesFoundWithRange6NotEmpty() {
      return this.profilesFoundWithRange6.length > 0
    },
    ...mapGetters([
      'emailVerified',
      'profileValid',
      'gigId',
      'gigLocation',
      'gigArchived',
      'gigProfilesFound',
      'gigProfilesFoundRefreshKey'
    ])
  },
  watch: {
    gigProfilesFoundRefreshKey() {
      this.refreshProfiles += 1
    },
  },
  methods: {
    findProfiles(skill) {
      if (skill && this.gigLocation) {
        this.findingProfiles = true
        const fp = httpsCallable(fbfuncs, 'findProfiles')
        fp({ latlng: this.gigLocation.latlng, skill: skill, gig: this.gigId, fev: this.$store.getters.frontendVersion })
          .then((result) => {
            const data = result.data
            if (data && Object.keys(data).length) {
              this.showSorryCard = false
              this.$store.commit('setGigProfilesFound', data)
            } else {
              this.showSorryCard = true
              this.$store.commit('setGigProfilesFound', {})
              eventBus.$emit('appWarningSet', { color: 'warning', message: 'No profiles found' })
            }
            this.findingProfiles = false
          })
          .catch((e) => {
            this.$store.commit('setGigProfilesFound', {})
            this.findingProfiles = false
            eventBus.$emit('appWarningSet', { message: 'Search failed: ' + e })
          });
      } else {
        this.$store.commit('setGigProfilesFound', {})
      }
    },
  },
  created() {
    eventBus.$emit('appAlterAppBar', {
      buttons: [ { show: false }, { show: false }, { show: false } ]
    })
  }
}
</script>