<template>
  <div>
    <tab-title>Areas</tab-title>
    <tab-content>
      <v-row>
        <v-col cols="12" sm="8" offset-sm="2">
          <action-card class="mb-4"> For performers: Type your chosen location, click the search button and choose from the list. Adjust the size of the area by clicking the pin button and moving the slider.</action-card>
          <action-card v-if="showSubscribeWarning" type="info" to-route="setsubscriptionconfig" class="mb-4">
            Your skills and areas are listed in the directory.
            If you'd like to respond to offers, upgrade to FORTE by clicking here.
          </action-card>
          <action-card v-if="showListingWarning" type="warning" class="mb-4">
            You need to add both your skills and areas to be listed in the directory.
          </action-card>
          <area-editor :disabled="!emailVerified || !profileValid" :maxCount="maxAreaCount"></area-editor>
        </v-col>
      </v-row>
    </tab-content>
  </div>
</template>

<script>
import TabTitle from '@/components/TabTitle.vue'
import TabContent from '@/components/TabContent.vue'
import ActionCard from '@/components/ActionCard.vue'
import AreaEditor from '@/components/AreaEditor.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'ProfileAreasTab',
  components: { TabTitle, TabContent, ActionCard, AreaEditor },
  computed: {
    showSubscribeWarning() {
      return (!!this.profileSkillCount && !!this.areaCount)
        && !this.accountSubscribed
    },
    showListingWarning() {
      return (!!this.profileSkillCount && !this.areaCount)
        || (!this.profileSkillCount && !!this.areaCount)
    },
    ...mapGetters([
      'emailVerified',
      'profileValid',
      'profileSkillCount',
      'accountSubscribed',
      'maxAreaCount',
      'areaCount'
    ])
  },
}
</script>