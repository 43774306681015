<template>
  <div>
    <tab-title>Subscription</tab-title>
    <tab-content>
      <subscription></subscription>
    </tab-content>
  </div>
</template>

<script>
import TabTitle from '@/components/TabTitle.vue'
import TabContent from '@/components/TabContent.vue'
import Subscription from '@/views/SubscriptionView.vue'

export default {
  name: 'SubscriptionConfigTab',
  components: { TabTitle, TabContent, Subscription },
}
</script>